import {get,post} from 'axios'
import ls from 'localstorage-slim';
export function getResponseFromCache(orderDetails, shipmentDetails, customerDetails, order_id, initials, shipment_id){
   if(orderDetails){
        const order_reference_id = orderDetails.order_reference_id
        const response_initials = customerDetails.first_name.substring(0,1)+customerDetails.last_name.substring(0,1)
        const response_shipment_id = shipmentDetails.shipment_id
       //TODO: validate shipment and initials and ordertype ensure that this validation logic is same on omni backend
        const order_parts = order_reference_id.split('-');
        const order_number = order_parts[0];
        if(order_number.toLowerCase() === order_id.toLowerCase() && initials.toUpperCase() == response_initials.toUpperCase() && shipment_id == response_shipment_id){
            return orderDetails
        }else{
            return undefined
        }
    }
   return undefined     
}

export const  setLocalStorage = async (search, order_id, shipment_id) =>{
    let ordersObj={}
    let shipments=[]
    const headers = {
        'x-api-key': process.env.REACT_APP_API_KEY
    };
    console.log('process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT',process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT )
    try {
        const res= await get(`${process.env.REACT_APP_ORDER_API_URL}/order_details${search}`, { headers })
        if(res.status=='200' && res.data.status != '400' &&(!res.data.name)){
            ls.set(`customer_${order_id}`,res.data.customer, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
            ls.set(`store_${order_id}`,res.data.store, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
            ordersObj=res.data.order
            if (typeof ordersObj.error_message !== "undefined") {
                ls.set(`orders_${order_id}`,ordersObj, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
            }
            if (Array.isArray(res.data.order.shipment)) {
                res.data.order.shipment.forEach(element => {
                    ls.set(`shipment_${element.shipment_id}`,element, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
                    if (element.shipment_id == shipment_id) {
                        res.data["shipment"]=element
                    }
                    shipments.push(element.shipment_id)
                });
            } else {
                ls.set(`shipment_${res.data.order.shipment.shipment_id}`,res.data.order.shipment, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
                shipments.push(res.data.order.shipment.shipment_id)
                res.data["shipment"]=res.data.order.shipment
            }

            ordersObj.shipment=shipments
            console.log(ordersObj);
            ls.set(`orders_${order_id}`,ordersObj, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
            ls.set(`shipment_ids_${order_id}`,shipments)
            res.data["orders"]=ordersObj
            return res.data
        }
    } catch (e) {
        console.log(e);
        return new Promise(function(resolve, reject) {
            resolve(false)
        });
    }
}


export const  setDeliveryLocalStorage = async (search, order_id) =>{
    let ordersObj={}
    let error = false;
    const headers = {
        'x-api-key': process.env.REACT_APP_API_KEY
    };
    console.log('process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT',process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT )
    try {
        const res= await get(`${process.env.REACT_APP_DELIVERY_ORDER_API_URL}/delivery_tracker${search}`, { headers })
        if (res.data.error) {
            return res.data
        }
        if(res.status=='200' && res.data.status != '400' &&(!res.data.name)){
            ls.set(`customer_${order_id}`,res.data.customer, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
            ls.set(`store_${order_id}`,res.data.store, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
            ordersObj=res.data.order
            ls.set(`orders_${order_id}`,ordersObj, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
            res.data["orders"]=ordersObj
            return res.data
        }
    } catch (e) {
        return new Promise(function(resolve, reject) {
            resolve(false)
        });
    }
}
