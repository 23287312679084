import React, {useEffect, useState} from "react";
import map from "../images/icons/normal_location_on_u30.svg";
import moment from "moment";
import arrow_down from "../images/icons/arrow_down.svg";
import phone from "../images/icons/phone_icon.svg";
import arrow_up from "../images/icons/arrow_up.svg";

const StoreInformation = (props) => {

    const [currentWeek, setCurrentWeek] = useState(0)
    const [open, setOpen] = useState(false);
    const [storeOpen, setStoreOpen] = useState('')
    const [storeClose, setStoreClose] = useState('')

    useEffect(async ()=> {
        getStoreHours(props.store)
    },[])

    const getStoreHours = (store) =>{
        const today = store.expanded_hours[0][moment().format("YYYY-MM-DD")];
        setStoreClose(today.close)
        setStoreOpen(today.open)
        return store.expanded_hours;
    }

    const nextWeek = e => {
        setCurrentWeek(currentWeek+1);
    }

    const previousWeek = e => {
        setCurrentWeek(currentWeek-1);
    }

    const toggleStoreHours = e => {
        setOpen(!open);
        setCurrentWeek(0);
        let status = document.querySelector("#store_hours_toggle").dataset.status;
        if (status == "collapsed") {
            status = "expanded";
            document.querySelector("#store_hours_toggle").src = arrow_up;
        } else {
            status = "collapsed";
            document.querySelector("#store_hours_toggle").src = arrow_down;
        }
        document.querySelector("#store_hours_toggle").dataset.status = status;
    }

    return (
        <div className="rectangle" id="store-details">
            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <img src={map} style={{width: "13px", hight: "18px", marginRight: "5px"}}/>
                    </div>
                    <div className="col-10">
                        <a href={`http://maps.google.com/?q=Party+City+${props.store.street_1}+${props.store.city}+${props.store.province}+${props.store.postal_code}`}
                           className="storeAddress" target={'_blank'}><b>{props.store.store_name} </b></a>
                        <div style={{width: "268px", height: "24px"}}>
                            {
                                isNaN(parseInt(storeClose)) &&
                                <>
                                    <b style={{color:"red"}}>Closed <b style={{color:"black"}}>-</b> Today</b>
                                </>
                            }
                            {
                                !isNaN(parseInt(storeClose)) &&
                                <>
                                    {
                                        moment().isBefore(moment(moment().format("YYYY-MM-DD") + " " + storeOpen).subtract(30, "minutes")) ?
                                            <b style={{color:"red"}}>Closed</b> :
                                            moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + storeClose).subtract(0, "minutes")) ?
                                                <b style={{color:"red"}}>Closed</b> :
                                                moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + storeClose).subtract(30, "minutes")) ?
                                                    <b style={{color:"red"}}>Closing Soon</b> :
                                                    moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + storeOpen).subtract(0, "minutes")) ?
                                                        <b style={{color:"green"}}>Open</b> :
                                                        <b style={{color:"red"}}>Opening Soon</b>
                                    } - {moment(moment().format("YYYY-MM-DD") + " " + storeOpen).format("h:mmA")}
                                    - {moment(moment().format("YYYY-MM-DD") + " " + storeClose).format("h:mmA")}
                                </>
                            }
                        </div>
                        <div className="store-hours-subheader">
                            <div style={{textAlign:"left"}}>
                                <div className="row">
                                    <div className="col-6" style={{textAlign:"left",  paddingRight:"0px"}}>
                                        <a href="#"
                                           className="store-hours"
                                           onClick={toggleStoreHours}
                                           style={{textAlign: "left"}}
                                        >
                                            Store hours
                                        </a>
                                        <img id="store_hours_toggle" src={arrow_down} data-status="collapsed"/>
                                    </div>
                                    <div className="col-6" style={{textAlign:"right", paddingRight:"0px", paddingLeft:"0px"}}>
                                        <img src={phone} alt="phone"
                                             style={{width: "15px", height: "15px", marginRight: "2px"}}/>
                                        <a href={`tel:${props.store.phone_number}`} style={{textAlign: "right"}}
                                           className="store-hours">{props.store.phone_number}</a></div>
                                </div>
                            </div>
                        </div>

                        {open &&


                        <>
                            {
                                currentWeek !== 0 &&
                                <>
                                    <b className="week-range">Week {moment(Object.keys(props.store.expanded_hours[currentWeek])[0]).format("M/DD/YYYY")} to {moment(Object.keys(props.store.expanded_hours[currentWeek])[(Object.keys(props.store.expanded_hours[currentWeek]).length-1)]).format("M/DD/YYYY")} </b>
                                </>
                            }

                            {
                                Object.keys(props.store.expanded_hours[currentWeek]).map(key => {
                                    return (props.store.expanded_hours[currentWeek][key].status == "closed") ?
                                        <div className="day row" data-today={key == moment().format("YYYY-MM-DD") ? "true" : "false"}>
                                            <div className="col-5">
                                                {props.store.expanded_hours[currentWeek][key].dow}:
                                            </div>
                                            <div className="col-7" style={{textAlign:"right", paddingLeft:"0px"}}>
                                                Closed
                                            </div>
                                        </div>
                                        :
                                        <div className="day row" data-today={key == moment().format("YYYY-MM-DD") ? "true" : "false"}>
                                            <div className="col-5">
                                                {props.store.expanded_hours[currentWeek][key].dow}:
                                            </div>
                                            <div className="col-7" style={{textAlign:"right", paddingLeft:"0px"}}>
                                                {`   ${moment( (moment().format("YYYY-MM-DD") + " " + props.store.expanded_hours[currentWeek][key].open) ).format("h:mmA")} - ${moment( (moment().format("YYYY-MM-DD") + " " + props.store.expanded_hours[currentWeek][key].close) ).format("h:mmA")}`}
                                            </div>
                                        </div>
                                })
                            }

                            {
                                currentWeek !== 0 &&
                                <>
                                    <button className="week-navigation" id="week-previous" onClick={previousWeek}>&lt; Previous week</button>
                                </>
                            }

                            {
                                currentWeek < (Object.keys(props.store.expanded_hours).length - 1) &&
                                <>
                                    <button className="week-navigation" id="week-next" onClick={nextWeek}>Next week &gt;</button>
                                </>
                            }
                        </>
                        }
                    </div>
                </div>
                {
                    open &&
                    <>
                        <br style={{clear:"both"}}/>
                        <div className="row" style={{fontSize:"13px",width:"100%"}}>
                            <div className="col-12">
                                <b>*Order Pickup Deadline:</b><br/>
                                <ol style={{paddingInlineStart:"15px"}}>
                                    <li>Orders must be picked up by your Pickup Date.</li>
                                    <li>For unclaimed orders after the Pickup Date, inflated balloons will be discarded (non-refundable).  All other merchandise will be canceled, returned to shelves and a refund issued.</li>
                                </ol>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default StoreInformation;