import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import moment from "moment";
import ls from "localstorage-slim";


const SMGFeedbackFull = (props) => {

    const applyModalStyle = () => {
        var style = document.createElement('style');
        style.innerHTML = '.ReactModal__Content--after-open {   inset: 0px !important; top: calc(100vh / 2 - 90px) !important; width: 350px !important; height:180px !important; padding: 10px !important; overflow:hidden !important;  margin-left: auto !important; margin-right: auto !important;} .ReactModal__Overlay--after-open {\n' +
            '    background-color: rgba(0, 0, 0, 0.75) !important;\n' +
            '}';
        document.head.appendChild(style);
    }

    const feedback = () => {
        if (!props.active) {
            return;
        }
    }

    const inactive = e => {
        if (typeof props.setActive === 'function') {
            props.setActive(false);
        }
    }

    const survey = () => {
        const orders = props.orders;
        const shipments = props.shipments;
        const store = props.store;

        ls.remove(`orders_${orders.order_reference_id}`)
        ls.remove(`store_${orders.order_reference_id}`)
        ls.remove(`shipment_${shipments.shipment_id}`)
        ls.flush();

        let url = " https://partycityfeedback.com/?";
        url += "S=" + store.store_id;
        url += "&T=" + orders.order_reference_id;
        url += "&D=" + moment(shipments.picked_up_dttm).format("YYYY-MM-DD");
        url += "&O=" + moment(orders.order_date).format("YYYY-MM-DD");
        url += "&A=" + orders.order_total;
        url += "&V=" + ((shipments.order_type == "BALLOON-CURBSIDE" || shipments.order_type == "CURBSIDE") ? "3" : "2");
        url += "&Q=";
        url += "&I=5";
        url += "&H=" + moment(shipments.picked_up_dttm).format("hhmm");
        url += "&B=" + ((orders.order_content == "H") ? "2" : "1");
        window.location.href = url;
    }

    useEffect(()=> {
        feedback()
        applyModalStyle();
    },[props.active,feedback])


    return (
        <div>
            <Modal
                isOpen={props.active}
                onRequestClose={!props.active}
            >
                <p style={{textAlign:"center",fontSize:"16px", padding:"10px"}}>
                    <b>Completion of the survey should be done after you receive the order. Do you want to proceed?</b>
                    <br/>
                    <br/>
                    <button className="closeOMWModal" onClick={survey}>Yes</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="closeOMWModal" onClick={inactive}>No</button>
                </p>
            </Modal>
        </div>
    );
};



export default SMGFeedbackFull;