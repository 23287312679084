import React, { useState, useEffect } from 'react';
import {Row, Col, Alert, Card} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import ls from 'localstorage-slim';
import Layout from './Layout';
import './../microsite.css'
import {getResponseFromCache, setLocalStorage} from './../util/util.js'
import {
    useLocation, useNavigate
} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay-ts';
import PageHeader from "./PageHeader";
import VehicleFlowFooter from "./VehicleFlowFooter";
import ErrorPage from "./ErrorPage";
/**
 * bag location assets
 */
import driver from './../images/icons/bag_location/driver.svg'
import passenger from './../images/icons/bag_location/passenger.svg'
import back_left from './../images/icons/bag_location/back_left.svg'
import back_right from './../images/icons/bag_location/back_right.svg'
import trunk from './../images/icons/bag_location/trunk.svg'
import trunk_copy from './../images/icons/bag_location/trunk_copy.svg'
import driver_active from './../images/icons/bag_location/driver_active.svg'
import passenger_active from './../images/icons/bag_location/passenger_active.svg'
import back_left_active from './../images/icons/bag_location/back_left_active.svg'
import back_right_active from './../images/icons/bag_location/back_right_active.svg'
import trunk_active from './../images/icons/bag_location/trunk_active.svg'
import trunk_active_copy from './../images/icons/bag_location/trunk_active_copy.svg'
import UTag from "./UTag";
import moment from "moment";

const BagLocation = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const orderParam = new URLSearchParams(search).get('o')
    const order_id = orderParam.substring(2,orderParam.length);
    const initials = orderParam.substring(0,2);
    const shipment_id = new URLSearchParams(search).get('s');
    const order_type = new URLSearchParams(search).get('t');
    const [bagLocation, setBagLocation] = useState(ls.get('bagLocation') || "")
    const [orders, setOrders] = useState({})
    const [shipments, setShipments] = useState({})
    const [store, setStore] = useState({})
    const [customer, setCustomer] = useState({})
    const [balloonsMessage, setBalloonsMessage] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [satisfied, setSatisfied] = useState(false)
    const [omniResponse, setOmniResponse] = useState()
    const [loading, setLoading] = useState(false)
    const [isExpired, setIsExpired] = useState(false);

    const selectBagLocation = value => {
        setBagLocation(value)
        setSatisfied(true)
        ls.set('bagLocation', value)
    }

    useEffect(async ()=> {
        window.scrollTo(0,0)
        let currentPage=ls.get(`currentPage_${shipment_id}`)
        currentPage===null? navigate(`/microsite${search}`): navigate(`/${currentPage}${search}`)
        let orders = ls.get(`orders_${order_id}`)
        let customer = ls.get(`customer_${order_id}`)
        let store = ls.get(`store_${order_id}`)
        let shipments = ls.get(`shipment_${shipment_id}`)

        if(orders === null){
            setLoading(true)
            const res = await setLocalStorage(search, order_id, shipment_id)
            if(res){
                setOrders(res.orders)
                setStore(res.store)
                setCustomer(res.customer)
                setShipments(res.shipment);
                setLoading(false)
            }else{
                setLoading(false)
                setApiError(true)
            }
        }else{
            setLoading(true)
            getResponseFromCache(orders, shipments, customer, order_id, initials, shipment_id) ? setApiError(false) : setApiError(true)
            setOrders(orders)
            setCustomer(customer)
            setStore(store)
            setShipments(shipments);
            setLoading(false)
        }
        if (currentPage == "bagLocation") {
            isFlowSatisfied();
        }

        let vehicle_type = ls.get(`vehicleType`);

        let balloon_copy = false;

        switch (vehicle_type) {
            case "Minivan":
            case "SUV":
            case "Crossover":
                balloon_copy = false;
                break;
            default:
                balloon_copy = true;
                break;
        }

        setBalloonsMessage(balloon_copy);


        ls.flush();
    },[])


    const isFlowSatisfied = () => {
        const bag_location = ls.get(`bagLocation`);
        let result = true;
        if (bag_location == null || bag_location == "" ) {
            result = false;
        }
        setSatisfied(result)
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout>
            {(apiError || isExpired) &&
                <ErrorPage isExpired={isExpired} order_id={orders.order_reference_id}/>
                }
                <PageHeader
                    headerText={(shipments.order_type == "BALLOON-PICKUP" || shipments.order_type == "BOPIS") ? "In Store Pickup" : "Curbside Pickup"}
                />
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <Row>
                    <Col id="vehicle-header"><span style={{color:"#018937"}}>STEP 2: </span>Bag Location</Col>
                </Row>
                <Row>
                    <Col id="vehicle-information">Where would you like your bags loaded in the vehicle?</Col>
                </Row>
                <br/>
                <br/>

                <div className="row">
                    <div className="col-6 bag_right">
                        <img src={(bagLocation == "driver") ? driver_active : driver} onClick={() => selectBagLocation('driver')} style={{width:"163px",height:"138px"}} />
                    </div>
                    <div className="col-6 bag_left">
                        <img src={(bagLocation == "passenger") ? passenger_active : passenger} onClick={() => selectBagLocation('passenger')} style={{width:"163px",height:"138px"}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 bag_right">
                        <img src={(bagLocation == "backleft") ? back_left_active : back_left} onClick={() => selectBagLocation('backleft')} style={{width:"163px",height:"61px"}} />
                    </div>
                    <div className="col-6 bag_left">
                        <img src={(bagLocation == "backright") ? back_right_active : back_right} onClick={() => selectBagLocation('backright')} style={{width:"163px",height:"61px"}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 bag_center">
                        <img src={(bagLocation == "trunk") ? (balloonsMessage) ? trunk_active_copy : trunk_active : (balloonsMessage) ? trunk_copy : trunk} onClick={() => selectBagLocation('trunk')} style={{width:"326px",height:"75px"}} />
                    </div>
                </div>
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <br/>
                <UTag type="load"
                      page_name={ls.get(`currentPage_${shipment_id}`)}
                      customer={ls.get(`customer_${order_id}`)}
                      orders={ls.get(`orders_${order_id}`)}
                      shipments={ls.get(`shipment_${shipment_id}`)}
                      store={ls.get(`store_${order_id}`)}
                      customer_id={ls.get(`CustomerKey_${(!ls.get(`customer_${order_id}`) ? "" : ls.get(`customer_${order_id}`).email_address)}`)}
                      vehicle_info={{"vehicle_type": "", "vehicle_color": "", "vehicle_make": "", "bag_location": ""}}
                />
                <VehicleFlowFooter
                    satisfied={satisfied}
                />
            </Layout>
        </LoadingOverlay>
    );
};

export default BagLocation;