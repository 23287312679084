import React, { useState } from 'react';
import {Button, Card, Row, Col} from 'react-bootstrap'
import PageHeader from "./PageHeader";
import Layout from './Layout';
import moment from 'moment';
import './../microsite.css'
import LoadingOverlay from 'react-loading-overlay-ts';
import {
    useLocation
} from "react-router-dom";
import Barcode from "react-barcode";

const ErrorPage = ({isExpired, order_id, store_name, pickedup_time, isCancelled, isMixedOrder, errorMessage = false, genericError = false, customHeader = false, barCodeFlag=false}) => {
    let search = useLocation().search;
    let order_type=""
    let order_suffix=""
    let  order_id_param
    const [loading, setLoading] = useState(false)
    if(search){
        const orderParam = new URLSearchParams(search).get('o')
        console.log('search#####',search)
        order_id_param = orderParam.substring(2,orderParam.length);
        order_type = new URLSearchParams(search).get('t');
        const order_type_suffix = order_type.substring(2,order_type.length);
        const order_type_prefix = order_type.substring(0,2);
        order_suffix = "B" + order_type_suffix;
        switch (order_type_prefix) {
            case "PO":
                order_suffix = "P" + order_type_suffix;
                break;
            case "CS":
                order_suffix = "C" + order_type_suffix;
                break;
        }
    }
    return (
    
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
        <Layout >
           <PageHeader
                headerText={(!customHeader) ? "Party Pickup" : customHeader}
            />
            <br style={{clear:"both"}}/>
            <br style={{clear:"both"}}/>
            <br style={{clear:"both"}}/>
            <br style={{clear:"both"}}/>

            {genericError &&
            <>
                <Card >
                    <Row>
                        <Col>
                            <div id="errorMessage">
                                {errorMessage}.
                            </div>
                        </Col>
                    </Row>
                    {barCodeFlag &&
                    <>
                        <Row>
                            <Col style={{textAlign:"center"}}>
                                <br/>
                                <Barcode displayValue={false} margin={5} value={order_id} />
                            </Col>
                        </Row>
                    </>
                    }

                </Card>
            </>
            }

            {(isExpired && !isCancelled && search && !genericError) &&
            <Card >
                <Row>
                    <Col>
                        <div id="errorMessage"> It appears that your order
                            <br/>
                            <b>{order_id} </b>
                            <br/>
                            {!errorMessage &&
                            <>
                                at Party City {store_name} has already been picked up on {moment(pickedup_time).format("MMM Do")}.
                            </>
                            }
                            {errorMessage !== false &&
                            <>
                            {errorMessage}.
                            </>
                            }
                        </div>

                    </Col>
                </Row>

            </Card>
            }
            {(isCancelled && !isExpired && search  && !genericError) &&
            <Card >
                <Row>
                    <Col>

                        <div id="errorMessage"> It appears that your order
                            <br/>
                            <b>{order_id} </b>
                            <br/>

                            at Party City {store_name}&nbsp;
                            {isMixedOrder &&
                            <>
                                has been canceled and merchandise other than inflated balloons will be refunded.
                            </>
                            }
                            {!isMixedOrder &&
                            <>
                                has been Canceled and will be refunded.
                            </>
                            }


                        </div>
                    </Col>
                </Row>

            </Card>
            }
            {(!isExpired && !isCancelled && search  && !genericError) &&
            <Card>
                <Row>
                    <Col>

                        <div id="errorMessage">
                            {errorMessage !== false &&
                            <>
                                {errorMessage}.
                            </>
                            }
                            {errorMessage == false &&
                            <>
                                <b>{`order # ${order_id_param}-${order_suffix}`}</b>
                                <br/>
                                We experienced a problem retrieving your order.  Either we were unable to locate your order number or it is no longer available for pickup.  Please contact the store if you need further assistance.
                            </>
                            }
                        </div>
                    </Col>
                </Row>
            </Card>
            }
            {(!search  && !genericError) &&
            <Card>
                <Row>
                    <Col>

                        <div id="errorMessage">
                            It appears there was an issue with your request. </div>
                    </Col>
                </Row>
            </Card>
            }
        </Layout>
        </LoadingOverlay>
    );
};

export default ErrorPage;