import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import {Row, Col, Alert, Card, Image} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import ls from 'localstorage-slim';
import Layout from './Layout';
import './../microsite.css'
import './../reschedule.css'
import './../extend_restock.css'
import {
    useLocation, useNavigate
} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay-ts';
import PageHeader from "./PageHeader";
import ErrorPage from "./ErrorPage";
import moment from "moment";
import {post} from "axios";

const ExtendRestock = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const orderParam = new URLSearchParams(search).get('o')
    const order_id = orderParam.substring(2,orderParam.length);
    const initials = orderParam.substring(0,2);
    const auth = new URLSearchParams(search).get('auth');
    const order_type = new URLSearchParams(search).get('t');
    const shipment_id = new URLSearchParams(search).get('s');
    const restock_extend_days = new URLSearchParams(search).get('restock_extend_days');
    const cb = new URLSearchParams(search).get('cb');
    const team = new URLSearchParams(search).get('team');
    const [errorMessage, setErrorMessage] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isExpired, setIsExpired] = useState(false);

    useEffect(async ()=> {
        ls.flush();
    },[])

    const back = () => {
        window.location.href = decodeURIComponent(cb);
    }

    const updateCache = () => {
        ls.remove(`orders_${order_id}`);
        ls.remove(`store_${order_id}`);
        ls.remove(`customer_${order_id}`);
    }

    const extendRestock = async () => {
        const body={"action":"extend_restock","o":orderParam,"t": order_type, "user_id": "Customer", "s": shipment_id, "auth": auth}
        setLoading(true)
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        try {
            const res = await post(`${process.env.REACT_APP_ORDER_API_URL}/extend_restock`, body, {headers})
            console.log(res);
            if (res.data.success) {
                setLoading(false);
                updateCache()
                back()
            } else {
                setLoading(false);
                if (typeof res.data.error == "undefined") {
                    setErrorMessage("Network issue")
                } else {
                    setErrorMessage(res.data.error)
                }
                setApiError(true)
            }
        } catch (e) {
            setLoading(false);
            setErrorMessage("Network issue")
            setApiError(true)
        }
        setLoading(false);
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout>
                {(apiError || isExpired) &&
                    <>
                        <ErrorPage isExpired={true} order_id={false} store_name={false} pickedup_time={false} isCancelled={false} isMixedOrder={false} errorMessage={errorMessage} genericError={true} customHeader="In-Store or Curbside Pickup"/>
                        <button className="reschedule-cancel-error" onClick={back}>Cancel</button>
                    </>
                }

                {(!apiError) &&
                    <>

                        <PageHeader
                            headerText="In-Store or Curbside Pickup"
                        />
                        <br style={{clear: "both"}}/>
                        <br style={{clear: "both"}}/>
                        <br style={{clear: "both"}}/>
                        <br style={{clear: "both"}}/>

                        <strong>Need more time to pick up your order?  Click "Extend" below, and we will add {restock_extend_days} more days to your pickup time.</strong>
                        <br/>
                        <div id="button-container">
                            <button className="extend-button" onClick={extendRestock}>Extend</button>
                            <br/>
                            <button className="cancel-button" onClick={back}>Cancel</button>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>


                    </>
                }

            </Layout>
        </LoadingOverlay>
    );
};

export default ExtendRestock;