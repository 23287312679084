import React, { useState, useEffect } from 'react';
import {Row, Col, Card, Button, Alert} from 'react-bootstrap'
import Layout from './Layout';
import {getResponseFromCache} from './../util/util.js'
import {get, post} from 'axios'
import {
    useLocation, useNavigate
} from "react-router-dom";
import ls from 'localstorage-slim';
import LoadingOverlay from 'react-loading-overlay-ts';
import VehicleFlowFooter from "./VehicleFlowFooter";
import './../microsite.css'
/**
 * vehicle assets
 */
import coupe from './../images/icons/vehicles/2_door_coupe.svg'
import compact from './../images/icons/vehicles/compact.svg'
import convertible from './../images/icons/vehicles/convertible.svg'
import crossover from './../images/icons/vehicles/crossover.svg'
import minivan from './../images/icons/vehicles/minivan.svg'
import pickup from './../images/icons/vehicles/pickup.svg'
import sedan from './../images/icons/vehicles/4_door_sedan.svg'
import sports_car from './../images/icons/vehicles/sports_car.svg'
import suv from './../images/icons/vehicles/suv.svg'
import other from './../images/icons/vehicles/other.png'
import other_selected from './../images/icons/vehicles/other_selected.png'
import {setLocalStorage} from "./../util/util";
import PageHeader from "./PageHeader";
import ErrorPage from "./ErrorPage";
import UTag from "./UTag";

const vehicles = [
    "Minivan",
    "SUV",
    "Pickup",
    "Sedan",
    "Sports Car",
    "Crossover",
    "Coupe",
    "Compact",
    "Convertible"
];
const colors = [
    "White",
    "Black",
    "Silver",
    "Red",
    "Blue",
    "Green",
    "Orange",
    "Yellow"
];

const VehicleInfo = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const orderParam = new URLSearchParams(search).get('o')
    const order_id = orderParam.substring(2,orderParam.length);
    const initials = orderParam.substring(0,2);
    const shipment_id = new URLSearchParams(search).get('s');
    const order_type = new URLSearchParams(search).get('t');
    const [vehicleType, setVehicleType] = useState(ls.get("vehicleType")|| "")
    const [vehicleColor, setVehicleColor] = useState(ls.get("vehicleColor")|| "")
    const [carMakeModel, setCarMakeModel] = useState(ls.get("carMakeModel") || "")
    const [apiError, setApiError] = useState(false)
    const [orders, setOrders] = useState({})
    const [shipments, setShipments] = useState({})
    const [store, setStore] = useState({})
    const [customer, setCustomer] = useState({})
    const [loading, setLoading] = useState(false)
    const [satisfied, setSatisfied] = useState(false)
    const [isExpired, setIsExpired] = useState(false);
    const [vehicleDetailCalled, setVehicleDetailCalled] = useState(false);


    const selectVehicleColor = e =>{
        setVehicleColor(e.target.alt)
        ls.set(`vehicleColor`,e.target.alt);
    }

    const handleChange = (event) => {
        setCarMakeModel(event.target.value);
        ls.set(`carMakeModel`,event.target.value);
    }

    const vehicleDetails = async (email) => {
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        const res= await get(`${process.env.REACT_APP_ORDER_API_URL}/vehicle_details?email_id=${email}`, {headers})
        if(res.data.CustomerContactKey){
            ls.set(`CustomerContactKey_${email}`,res.data.CustomerContactKey)
            ls.set(`CustomerKey_${email}`,res.data.CustomerKey)
            if(res.data.Extn.ExtnVehicleMake)ls.set(`carMakeModel`,res.data.Extn.ExtnVehicleMake);
            if(res.data.Extn.ExtnVehicleType)ls.set(`vehicleType`,res.data.Extn.ExtnVehicleType);
            if(res.data.Extn.ExtnPackagePlacementPreference)ls.set(`bagLocation`,res.data.Extn.ExtnPackagePlacementPreference);
            if(res.data.Extn.ExtnVehicleColor)ls.set(`vehicleColor`,res.data.Extn.ExtnVehicleColor);
            if(res.data.Extn.ExtnVehicleMake)setCarMakeModel(res.data.Extn.ExtnVehicleMake)
            if(res.data.Extn.ExtnVehicleType)setVehicleType(res.data.Extn.ExtnVehicleType)
            if(res.data.Extn.ExtnVehicleColor)setVehicleColor(res.data.Extn.ExtnVehicleColor)
            if(res.data.OrganizationCode)ls.set(`OrganizationCode_${email}`,res.data.OrganizationCode)
            if(res.data.CustomerType)ls.set(`CustomerType_${email}`,res.data.CustomerType)
            setVehicleDetailCalled(true);
        }
        return res
    }

    useEffect(async ()=> {
        let currentPage=ls.get(`currentPage_${shipment_id}`)
        currentPage===null? await navigate(`/microsite${search}`): await navigate(`/${currentPage}${search}`)
        let orders = ls.get(`orders_${order_id}`)
        let customer = ls.get(`customer_${order_id}`)
        let store = ls.get(`store_${order_id}`)
        let shipments = ls.get(`shipment_${shipment_id}`)

        if(orders === null){
            setLoading(true)
            const res = await setLocalStorage(search, order_id, shipment_id)
            if(res){
                setOrders(res.orders)
                setStore(res.store)
                setCustomer(res.customer)
                setShipments(res.shipment);
                setLoading(false)
            }else{
                setLoading(false)
                setApiError(true)
            }
        }else{
            if (currentPage !== "vehicleinfo") {
                setLoading(true)
            }
            getResponseFromCache(orders, shipments, customer, order_id, initials, shipment_id) ? setApiError(false) : setApiError(true)
            setOrders(orders)
            setCustomer(customer)
            setStore(store)
            setShipments(shipments);
            setLoading(false)
        }

        if (currentPage == "vehicleinfo") {
            scrollBarEvent();
            isFlowSatisfied();
        }

        if((!vehicleColor || !vehicleType || !carMakeModel) && !vehicleDetailCalled){
            const resp =  await vehicleDetails(customer.email_address)
        }
        

        ls.flush();
    },[satisfied, vehicleType, vehicleColor, carMakeModel])

    const scrollBarEvent = () => {
        document.querySelector('#vehicle-list').addEventListener('scroll', hideCoverBar);
        document.querySelector('#vehicle-list').addEventListener('mousemove', hideCoverBar);
        document.querySelector('#color-list').addEventListener('scroll', hideCoverBarC);
        document.querySelector('#color-list').addEventListener('mousemove', hideCoverBarC);
        var showTimeout;
        var showTimeoutC;

        function hideCoverBar() {
            document.querySelector('#vehicle-list > .cover-bar').classList.add('hidden');
            clearTimeout(showTimeout);
            showTimeout = setTimeout(showCoverBar, 1000);

        }

        function showCoverBar() {
            document.querySelector('#vehicle-list > .cover-bar').classList.remove('hidden');
        }

        function hideCoverBarC() {
            document.querySelector('#color-list > .cover-bar').classList.add('hidden');
            clearTimeout(showTimeoutC);
            showTimeoutC = setTimeout(showCoverBarC, 1000);

        }

        function showCoverBarC() {
            document.querySelector('#color-list > .cover-bar').classList.remove('hidden');
        }
    }

    const vehicleCardEvent = e => {
        let element = e.target;
        if (e.target.className.indexOf("vehicle-card") === -1) {
            element = e.target.parentNode;
        }
        const name = element.name;
        setVehicleType(name)
        ls.set(`vehicleType`, name);
        isFlowSatisfied();
    }

    const colorCardEvent = e => {
        let element = e.target;
        if (e.target.className.indexOf("color-card") === -1) {
            element = e.target.parentNode;
            if (element.className.indexOf("color-card") === -1) {
                element =  element.parentNode;
            }
        }
        const name = element.name;
        setVehicleColor(name)
        ls.set(`vehicleColor`,name);
        isFlowSatisfied();
    }

    const scrollToElement = e => {
        const id = e.target.id;
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    }

    const customVehicleType = e => {
        const value = e.target.value;
        setVehicleType(value)
        ls.set(`vehicleType`, value);
        isFlowSatisfied();
    }

    const customVehicleColor = e => {
        const value = e.target.value;
        setVehicleColor(value)
        ls.set(`vehicleColor`,value);
        isFlowSatisfied();
    }

    const isFlowSatisfied = () => {
        const vehicle_type = ls.get(`vehicleType`);
        const vehicle_color = ls.get(`vehicleColor`);
        let result = true;
        if (vehicle_type == null || vehicle_type == "" || vehicle_type == "Other") {
            result = false;
        }
        if (vehicle_color == null || vehicle_color == "" || vehicle_color == "Other") {
            result = false;
        }
        setSatisfied(result)
    }

    return (

        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout>
            {(apiError || isExpired) &&
                <ErrorPage isExpired={isExpired} order_id={orders.order_reference_id}/>
                }
                {(orders && Object.keys(orders).length>0 && !apiError) &&
                <>

                    <PageHeader
                        headerText={(shipments.order_type == "BALLOON-PICKUP" || shipments.order_type == "BOPIS") ? "In Store Pickup" : "Curbside Pickup"}
                    />
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>

                    <Card>
                        <Row>
                            <Col id="vehicle-header"><span style={{color:"#018937"}}>STEP 1: </span>Vehicle Information</Col>
                        </Row>
                        <Row>
                            <Col id="vehicle-information">Entering your vehicle details will help us spot you when you’ve arrived.</Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col id="vehicle-type">Vehicle Type*</Col>
                        </Row>

                        <div id="vehicle-list">


                            <span>
                                <button className={(vehicleType == "Minivan") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="minivan" name="Minivan" onClick={vehicleCardEvent}>
                                    <img  src={minivan} className="vehicle-image" alt="Minivan" value="minivan" name="Minivan" style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="Minivan">Minivan</p>
                                </button>
                            </span>

                            <span>
                                <button className={(vehicleType == "SUV") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="suv" name="SUV"  onClick={vehicleCardEvent}>
                                    <img src={suv} className="vehicle-image" alt="SUV" value="suv"name="SUV"  style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="SUV">SUV</p>
                                </button>
                            </span>

                            <span>
                                <button className={(vehicleType == "Pickup") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="pickup" name="Pickup"  onClick={vehicleCardEvent}>
                                    <img  src={pickup} className="vehicle-image" alt="Pickup" value="pickup" name="Pickup" style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="Pickup">Pickup</p>
                                </button>
                            </span>

                            <span>
                                <button className={(vehicleType == "Sedan") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="sedan" name="Sedan"  onClick={vehicleCardEvent}>
                                    <img  src={sedan} className="vehicle-image" alt="Sedan" value="sedan" name="Sedan"  style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="Sedan" >4 Door Sedan</p>
                                </button>
                            </span>

                            <span>
                                <button className={(vehicleType == "Sports Car") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="sports_car" name="Sports Car"  onClick={vehicleCardEvent}>
                                    <img  src={sports_car} className="vehicle-image" alt="Sports Car" value="sports_car" name="Sports Car" style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="Sports Car">Sports Car</p>
                                </button>
                            </span>

                            <span>
                                <button className={(vehicleType == "Crossover") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="crossover" name="Crossover"  onClick={vehicleCardEvent}>
                                    <img  src={crossover}  className="vehicle-image" alt="Crossover" value="crossover" name="Crossover" style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="Crossover">Crossover</p>
                                </button>
                            </span>

                            <span>
                                <button className={(vehicleType == "Coupe") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="coupe" name="Coupe"  onClick={vehicleCardEvent}>
                                    <img  src={coupe} className="vehicle-image" alt="Coupe" value="coupe" name="Coupe" style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="Coupe">2 Door Coupe</p>
                                </button>
                            </span>

                            <span>
                                <button className={(vehicleType == "Compact") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="compact" name="Compact"  onClick={vehicleCardEvent}>
                                    <img  src={compact} className="vehicle-image" alt="Compact" value="compact" name="Compact" style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="Compact">Compact</p>
                                </button>
                            </span>

                            <span>
                                <button className={(vehicleType == "Convertible") ? "btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="convertible" name="Convertible"  onClick={vehicleCardEvent}>
                                    <img  src={convertible} className="vehicle-image" alt="Convertible" value="convertible" name="Convertible" style={{width: "100px" , height: "50px"}}/>
                                    <p className="vehicle-description" name="Convertible">Convertible</p>
                                </button>
                            </span>

                            <span>
                                <button className={(!vehicles.includes(vehicleType) && vehicleType !== "") ?"btn btn-default vehicle-card selected" : "btn btn-default vehicle-card"} value="other" name="Other" style={{width:"110px", height: "84px"}}  onClick={vehicleCardEvent}>
                                    <img  src={(!vehicles.includes(vehicleType) && vehicleType !== "") ? other_selected : other } className="vehicle-image" alt="Other" value="other" name="Other"  style={{width: "40px" , height: "40px"}}/>
                                    <p className="vehicle-description" name="Other" >Other</p>
                                </button>
                            </span>

                            <div className="cover-bar"></div>
                        </div>

                    </Card>

                    <div id="custom-vehicle" data-active={(!vehicles.includes(vehicleType) && vehicleType !== "") ? "active" : "inactive"}>
                        <br/>
                        <p id="custom-vehicle-header">Please enter custom vehicle type:</p>
                        <input id="custom-vehicle-input" value={(!vehicles.includes(vehicleType) && vehicleType !== ""  && vehicleType !== "Other") ? vehicleType : ""} onClick={scrollToElement}  onChange={customVehicleType}  />
                    </div>
                    <hr/>

                    <Card>
                        <Row>
                            <Col id="vehicle-color">Color*</Col>
                        </Row>
                        <div id="color-list">

                                <span>
                                    <button className="btn btn-default color-card" name="White" value="white"  onClick={colorCardEvent}>
                                        <div className={(vehicleColor == "White") ? "color selected" : "color"} id="color_white"></div>
                                        <p className="color-description">White</p>
                                    </button>
                                </span>

                            <span>
                                    <button className="btn btn-default color-card" name="Black" value="black" onClick={colorCardEvent}>
                                        <div className={(vehicleColor == "Black") ? "color selected" : "color"}  id="color_black"></div>
                                        <p className="color-description">Black</p>
                                    </button>
                                </span>

                            <span>
                                    <button className="btn btn-default color-card" name="Silver" value="silver" onClick={colorCardEvent}>
                                        <div className={(vehicleColor == "Silver") ? "color selected" : "color"}  id="color_silver"></div>
                                        <p className="color-description">Silver</p>
                                    </button>
                                </span>

                            <span>
                                    <button className="btn btn-default color-card" name="Red" value="red" onClick={colorCardEvent}>
                                        <div className={(vehicleColor == "Red") ? "color selected" : "color"}  id="color_red"></div>
                                        <p className="color-description">Red</p>
                                    </button>
                                </span>

                            <span>
                                    <button className="btn btn-default color-card" name="Blue" value="blue" onClick={colorCardEvent}>
                                        <div className={(vehicleColor == "Blue") ? "color selected" : "color"}  id="color_blue"></div>
                                        <p className="color-description">Blue</p>
                                    </button>
                                </span>

                            <span>
                                    <button className="btn btn-default color-card" name="Green" value="green" onClick={colorCardEvent}>
                                        <div className={(vehicleColor == "Green") ? "color selected" : "color"}  id="color_green"></div>
                                        <p className="color-description">Green</p>
                                    </button>
                                </span>

                            <span>
                                    <button className="btn btn-default color-card" name="Orange" value="orange" onClick={colorCardEvent}>
                                        <div className={(vehicleColor == "Orange") ? "color selected" : "color"} id="color_orange"></div>
                                        <p className="color-description">Orange</p>
                                    </button>
                                </span>

                            <span>
                                    <button className="btn btn-default color-card" name="Yellow" value="yellow" onClick={colorCardEvent}>
                                        <div className={(vehicleColor == "Yellow") ? "color selected" : "color"}  id="color_yellow"></div>
                                        <p className="color-description">Yellow</p>
                                    </button>
                                </span>

                            <span>
                                    <button className="btn btn-default color-card" name="Other" value="other" onClick={colorCardEvent}>
                                        <div className={(!colors.includes(vehicleColor) && vehicleColor !== "") ? "color selected" : "color"}  id="color_other"  name="Other">
                                            <img id="color-plus" src={(!colors.includes(vehicleColor) && vehicleColor !== "") ? other_selected : other}   style={{width: "30px" , height: "30px"}}/>
                                        </div>
                                        <p className="color-description">Other</p>
                                    </button>
                                </span>




                            <div className="cover-bar"></div>
                        </div>


                    </Card>
                    <div id="custom-color" data-active={(!colors.includes(vehicleColor) && vehicleColor !== "") ? "active" : "inactive"}>
                        <br/>
                        <p id="custom-color-header">Please enter custom color:</p>
                        <input id="custom-color-input" value={(!vehicles.includes(vehicleColor) && vehicleColor !== "" && vehicleColor !== "Other") ? ls.get(`vehicleColor`) : ""} onClick={scrollToElement} onChange={customVehicleColor} />
                    </div>
                    <hr/>

                    <p id="vehicle-make-header">Vehicle Make</p>
                    <div id="vehicle-make">
                        <input id="vehicle-make-input" value={(carMakeModel !== "") ? carMakeModel : ""} onClick={scrollToElement} onChange={handleChange} />
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <UTag type="load"
                          page_name={ls.get(`currentPage_${shipment_id}`)}
                          customer={ls.get(`customer_${order_id}`)}
                          orders={ls.get(`orders_${order_id}`)}
                          shipments={ls.get(`shipment_${shipment_id}`)}
                          store={ls.get(`store_${order_id}`)}
                          customer_id={ls.get(`CustomerKey_${(!ls.get(`customer_${order_id}`) ? "" : ls.get(`customer_${order_id}`).email_address)}`)}
                          vehicle_info={{"vehicle_type": "", "vehicle_color": "", "vehicle_make": "", "bag_location": ""}}
                    />

                    <VehicleFlowFooter
                        satisfied={satisfied}
                    />

                </>
                }
            </Layout>
        </LoadingOverlay>
    );
};




export default VehicleInfo;