import React from "react";
import Microsite  from "./components/Microsite";
import DeliveryTracker from "./components/DeliveryTracker";
import HomePage from "./components/HomePage";
import OnMyWay from "./components/OnMyWay";
import VehicleInfo from "./components/VehicleInfo";
import BagLocation from "./components/BagLocation";
import Reschedule from "./components/Reschedule";
import RescheduleDelivery from "./components/RescheduleDelivery";
import ExtendRestock from "./components/ExtendRestock";
import ParkingInfo from "./components/ParkingInfo";
import IamHere from "./components/IamHere";
import IamHereIS from "./components/IamHereIS";
import FeedBack from "./components/FeedBack";
import Layout from "./components/Layout";
import ErrorPage from "./components/ErrorPage";
import Maintenance from "./components/Maintenance";
import CancelOrder from "./components/CancelOrder";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
function App() {
  console.log('process.env.REACT_APP_MAINTENANCE###',process.env.REACT_APP_MAINTENANCE)
  return (
      <Layout>

        <Router>
          {  (maintenanceCheck()) &&
              <Routes>
                <Route exact path="/*" element={<Maintenance />}/>
              </Routes>
          }
          { (!maintenanceCheck()) &&
              <Routes>
                <Route exact path="/" element={<ErrorPage />}/>
                <Route exact path="/microsite" element={<Microsite />}/>
                <Route exact path="/deliverytracker" element={<DeliveryTracker />}/>
                <Route exact path="/vehicleinfo" element={<VehicleInfo />}/>
                <Route exact path="/baglocation" element={<BagLocation />}/>
                <Route exact path="/reschedule" element={<Reschedule />}/>
                <Route exact path="/rescheduleDelivery" element={<RescheduleDelivery />}/>
                <Route exact path="/parkinginfo" element={<ParkingInfo />}/>
                <Route exact path="/iamhere" element={<IamHere />}/>
                <Route exact path="/iamhereIS" element={<IamHereIS />}/>
                <Route exact path="/feedback" element={<FeedBack />}/>
                <Route exact path="/extendRestock" element={<ExtendRestock />}/>
                <Route exact path="/cancelOrder" element={<CancelOrder />}/>
              </Routes>
          }
        </Router>
      </Layout>
  );
}

function maintenanceCheck() {
  const path = window.location.pathname;
  const delivery_paths = ["/deliverytracker", "/rescheduledelivery"];
  if ((process.env.REACT_APP_MAINTENANCE==="true" && delivery_paths.indexOf(path.toLowerCase()) === -1) || (process.env.REACT_APP_MAINTENANCE_DELIVERY==="true" && delivery_paths.indexOf(path.toLowerCase()) !== -1)) {
    return true;
  }
  return false;
}

export default App;
