import React, { useEffect, useState } from 'react';
import {Row, Col, Modal, Alert, Card, Image} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import ls from 'localstorage-slim';
import Layout from './Layout';
import curbside from './../images/icons/curbside.svg'
import './../microsite.css'
import {getResponseFromCache, setLocalStorage} from './../util/util.js'
import {get, post} from 'axios'
import {
    useLocation, useNavigate
} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay-ts';
import PageHeader from "./PageHeader";
import ErrorPage from "./ErrorPage";
import second_contact from "../images/icons/second_contact.png";
import moment from "moment";
import UTag from "./UTag";
import SMGFeedbackFull from "./SMGFeedbackFull";
import Barcode from "react-barcode";

const bag_location_translation = {
    "driver": "Driver",
    "passenger": "Passenger",
    "backleft": "Back Left",
    "backright": "Back Right",
    "trunk": "Trunk"
};


const IamHere = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const orderParam = new URLSearchParams(search).get('o')
    const order_id = orderParam.substring(2,orderParam.length);
    const initials = orderParam.substring(0,2);
    const shipment_id = new URLSearchParams(search).get('s');
    const order_type = new URLSearchParams(search).get('t');
    const [callStore, setCallStore] = useState(false)
    const [orders, setOrders] = useState({})
    const [shipments, setShipments] = useState({})
    const [store, setStore] = useState({})
    const [customer, setCustomer] = useState({})
    const [apiError, setApiError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isExpired, setIsExpired] = useState(false);
    const [hg, setHG] = useState(false);
    const [hgPickedUp, setHGPickedUp] = useState(false);
    const [hgCancelled, setHGCancelled] = useState(false);
    const [balloonPickedUp, setBalloonPickedUp] = useState(false);
    const [balloonCancelled, setBalloonCancelled] = useState(false);
    const [balloon, setBalloon] = useState(false);
    const [hgReady, setHGReady] = useState(false);
    const [balloonReady, setBalloonReady] = useState(false);
    const [pickupTimeHG, setPickupTimeHG] = useState(false);
    const [pickupTimeBalloon, setPickupTimeBalloon] = useState(false);
    const [feedbackActive, setFeedbackActive] = useState(false);
    const orderContent={
        "H":  "Merchandise",
        "B":  "Balloon",
        "HB":  "Balloon and Hard Goods"
    }
    var ua = window.navigator.userAgent;
    console.log('ua@@@@@@@',ua)
    const handleClick = (event) =>{
        var ua = window.navigator.userAgent;
        console.log('ua@@@@@@@',ua)
        if (ua.indexOf('iPhone ') > 0) {
            event.preventDefault();
            window.open('', '_self', '');
            window.close();
        }else {
            window.open('', '_self', '');
            window.close();
        }
    }

    const isHG = async () => {
        let hg = false;
        let hgPickedUp = false;
        let hgCancelled = false;
        let hgPickUpTime = false;

        const order = ls.get( ( "orders_" + order_id ));
        const shipment_ids =  order.shipment;
        for (let key in shipment_ids) {
            let shipment = ls.get( ( "shipment_" + shipment_ids[key]  ) );
            if (shipment.order_type == "BOPIS" || shipment.order_type == "CURBSIDE") {
                hg = true;
                if (order.order_content == "HB") {
                    hgPickUpTime = shipment.scheduled_dttm;
                    setPickupTimeHG(shipment.scheduled_dttm);
                } else {
                    hgPickUpTime = shipment.restock_date;
                    setPickupTimeHG(shipment.restock_date);
                }
                if (shipment.status === "cancelled") {
                    hgCancelled = true;
                }
                if (shipment.status === "Picked Up") {
                    hgPickedUp = true;
                }
            }
        }
        setHGReady(true);
        setHGPickedUp(hgPickedUp);
        setHGCancelled(hgCancelled);
        setHG(hg);
        return {hg:hg,hgPickedUp:hgPickedUp,hgPickUpTime:hgPickUpTime}
    }

    const isBalloon = async () => {
        let balloon = false;
        let balloonReady = false;
        let balloonPickedUp = false;
        let balloonCancelled = false;
        let balloonPickUpTime = false;

        const order = ls.get( ( "orders_" + order_id ));
        const shipment_ids =  order.shipment;
        for (let key in shipment_ids) {
            let shipment = ls.get( ( "shipment_" + shipment_ids[key] ) );
            if (shipment.order_type == "BALLOON-PICKUP" || shipment.order_type == "BALLOON-CURBSIDE") {
                balloon = true;
                if (shipment.status === "Picked Up") {
                    balloonPickedUp = true;
                }

                if (!moment().isBefore(moment(shipment.scheduled_dttm).subtract(15, "minutes"))) {
                    balloonReady = true;
                }
                if (shipment.status === "cancelled") {
                    balloonCancelled = true;
                    balloonReady = false;
                }
                balloonPickUpTime = shipment.scheduled_dttm;
                setPickupTimeBalloon(shipment.scheduled_dttm);
            }
        }
        setBalloonReady(balloonReady);
        setBalloonPickedUp(balloonPickedUp);
        setBalloonCancelled(balloonCancelled);
        setBalloon(balloon);
        console.log(balloonReady)
        return {balloon:balloon,balloonReady:balloonReady,balloonPickedUp:balloonPickedUp,balloonPickUpTime:balloonPickUpTime}
    }

    const feedback = (e = true) => {
        setFeedbackActive(e)
    }


    useEffect(async ()=> {
        let currentPage=ls.get(`currentPage_${shipment_id}`)
        currentPage===null? navigate(`/microsite${search}`): navigate(`/${currentPage}${search}`)
        let orders = ls.get(`orders_${order_id}`)
        let customer = ls.get(`customer_${order_id}`)
        let store = ls.get(`store_${order_id}`)
        let shipments = ls.get(`shipment_${shipment_id}`)

        if(orders === null){
            setLoading(true)
            const res = await setLocalStorage(search, order_id, shipment_id)
            if(res){
                setOrders(res.orders)
                setStore(res.store)
                setCustomer(res.customer)
                setShipments(res.shipment);
                setLoading(false)
            }else{
                setLoading(false)
                setApiError(true)
            }
        }else{
            setLoading(true)
            getResponseFromCache(orders, shipments, customer, order_id, initials, shipment_id) ? setApiError(false) : setApiError(true)
            setOrders(orders)
            setCustomer(customer)
            setStore(store)
            setShipments(shipments);
            setLoading(false)
        }

        let store_cache = ls.get(`store_${order_id}`)
        const moment = require('moment-timezone');
        moment.tz.setDefault(store_cache.tz);

        const hgData = await isHG();
        const balloonData = await isBalloon();

        ls.remove(`currentPage_${shipment_id}`)
        // ls.remove(`orders_${order_id}`)
        ls.remove(`customer_${order_id}`)
        // ls.remove(`store_${order_id}`)
        // ls.remove(`shipment_${shipment_id}`)
        ls.flush();
    },[])

    const getMixedContent = () => {
        if (balloonReady && hgReady && !hgPickedUp && !balloonCancelled && !hgCancelled) {
            return "Merchandise and Balloons";
        } else {
            if (balloonReady) {
                return "Balloons";
            }
            if (hgReady && !hgPickedUp) {
                return "Merchandise";
            }
        }
        return "ERR - ORDER NOT READY";
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout align="center">

                {(apiError || isExpired) &&
                <ErrorPage isExpired={isExpired} order_id={orders.order_reference_id}/>
                }
                {(orders  && Object.keys(customer).length>0 && !apiError )&&
                <>
                    <PageHeader
                        headerText={(shipments.order_type == "BALLOON-PICKUP" || shipments.order_type == "BOPIS") ? "In Store Pickup" : "Curbside Pickup"}
                    />
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>

                    <Card>
                        <Row>
                            <Col className="thankYou">
                                Thanks For Checking In Curbside!
                            </Col>
                        </Row>
                        <Row>
                            <Col className="inStoreOrderDetails">
                                Please show this information to the team<br/>member when they arrive at your car.
                            </Col>
                        </Row>
                        <br/>
                        <div  className="rectangle1">
                            <br/>
                            <Row>
                                <Col className="orderInformationHeader">
                                    BAG LOCATION:
                                </Col>
                            </Row>
                            <Row className="confirmation-text">
                                <Col className="orderNumber">
                                    {
                                        (Object.keys(bag_location_translation).indexOf(ls.get(`bagLocation`)) !== -1) &&
                                            <>
                                                {bag_location_translation[ls.get(`bagLocation`)]}
                                            </>
                                    }
                                    {
                                        (Object.keys(bag_location_translation).indexOf(ls.get(`bagLocation`)) == -1) &&
                                        <>
                                            Not Found
                                        </>
                                    }
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col className="orderInformationHeader">
                                    ORDER INFORMATION:
                                </Col>
                            </Row>
                            <Row className="confirmation-text">
                                <Col className="orderNumber">
                                    {orders.order_reference_id}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <br/>
                                    <Barcode displayValue={false} margin={5} value={orders.order_reference_id} />
                                </Col>
                            </Row>
                            <Row className="confirmation-text">
                                <Col className="orderNumber">
                                    {`${customer.last_name}, ${customer.first_name.substring(0,1)}`}
                                </Col>
                            </Row>

                                    {
                                        typeof customer !== "undefined" &&
                                        <>
                                            {
                                                (customer.secondary_contact.first_name !== "" && customer.secondary_contact.last_name !== "") &&
                                                <>
                                                <Row className="confirmation-text">
                                                    <Col>
                                                    <div style={{fontSize:"18px"}}><img src={second_contact} style={{height:"25px",width:"25px",fontSize:"18px"}} /> {customer.secondary_contact.first_name} {customer.secondary_contact.last_name}</div>
                                                    </Col>
                                                </Row>
                                                </>
                                            }
                                        </>
                                    }

                            <Row className="confirmation-text">
                                <Col className="orderNumber">
                                    {
                                        orders.order_content==="H" ? orderContent["H"]
                                            : orders.order_content==="B" ? orderContent["B"]
                                            : getMixedContent()
                                    }
                                </Col>
                            </Row>
                            <br/>
                        </div>
                        <br/>
                        <Row>
                            <Col>
                                <Image src={curbside}  style={{width: "100px", height: "100px"}} alt="curbside pickup" fluid></Image>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="feedbacknote">
                                Please share your feedback below about your order pickup experience.
                            </Col>
                        </Row>
                    </Card>
                    <UTag type="load"
                          page_name={ls.get(`currentPage_${shipment_id}`)}
                          customer={ls.get(`customer_${order_id}`)}
                          orders={ls.get(`orders_${order_id}`)}
                          shipments={ls.get(`shipment_${shipment_id}`)}
                          store={ls.get(`store_${order_id}`)}
                          customer_id={ls.get(`CustomerKey_${(!ls.get(`customer_${order_id}`) ? "" : ls.get(`customer_${order_id}`).email_address)}`)}
                          vehicle_info={{"vehicle_type": ls.get(`vehicleType`), "vehicle_color": ls.get(`vehicleColor`), "vehicle_make": ls.get(`carMakeModel`), "bag_location": ls.get(`bagLocation`)}}
                    />
                    <SMGFeedbackFull
                        active={feedbackActive}
                        setActive={feedback}
                        orders={ls.get(`orders_${order_id}`)}
                        shipments={ls.get(`shipment_${shipment_id}`)}
                        store={ls.get(`store_${order_id}`)}
                    />
                    <button id="feedback-survey-button" onClick={feedback}>Share Your Feedback</button>
                </>
                }
            </Layout>
        </LoadingOverlay>

    );
};

export default IamHere;