import React, { useEffect, useState } from 'react';
import {Row, Col, Card, Alert} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import ls from 'localstorage-slim';
import Layout from './Layout';
import {getResponseFromCache} from './../util/util.js'
import {get, post} from 'axios'
import {
    Link, useLocation, useNavigate
   } from "react-router-dom";
   import LoadingOverlay from 'react-loading-overlay-ts';
   
const camelCase = require('camelcase');
const OnMyWay = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const orderParam = new URLSearchParams(search).get('o')
    const order_id = orderParam.substring(2,orderParam.length);
    const initials = orderParam.substring(0,2);
    const shipment_id = new URLSearchParams(search).get('s');
    const order_type = new URLSearchParams(search).get('t');
    
    const [apiError, setApiError] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const [omniResponse, setOmniResponse] = useState()
    const convertDate = (omniResponse) =>{
        let mydatetime= omniResponse.data.order.scheduled_dttm
        let mydate= mydatetime.substring(0,10)
        const _date = mydate.split('-');
        mydate = `${_date[1]}/${_date[2]}/${_date[0]}`
        let mytime= mydatetime.substring(11, mydatetime.length)
        const pickupday = new Date(`${mydate} ${mytime}`);
        let pickupTime= `${pickupday.getMonth()+1}/${pickupday.getDate()} @ ${pickupday.toLocaleString('en-US', { hour: 'numeric', hour12: true })}`
        return  pickupTime
    }

    const handleIamHere = () =>{
        if(omniResponse.data.order.order_type == 'BOPIS' ||  omniResponse.data.order.order_type == 'BALLOON-PICKUP'){
            navigate(`/iamhereIS${search}`)
            ls.set(`currentPage_${shipment_id}`,"iamhereIS")
          }else{
            navigate(`/vehicleinfo${search}`)
            ls.set(`currentPage_${shipment_id}`,"vehicleinfo")
          }
    }

    const handleBack = () => {
        navigate(`/microsite${search}`)
        ls.set(`currentPage_${shipment_id}`,"microsite")
    }
    useEffect(async ()=> {
        let apiResponse = ls.get(`orderApiResponse_${shipment_id}`)
        let currentPage=ls.get(`currentPage_${shipment_id}`)
        currentPage===null? navigate(`/microsite${search}`): navigate(`/${currentPage}${search}`)
        if(apiResponse === null){
            setLoading(true)
            const res= await get(`${process.env.REACT_APP_ORDER_API_URL}/order_details${search}`)
            if(res.status=='200' && res.data.status != '400'){
              setOmniResponse(res)
              setLoading(false)
              ls.set(`orderApiResponse_${shipment_id}`,res, { ttl:  process.env.REACT_APP_LOCAL_STORAGE_TIMEOUT })
            }
        }else{
            setLoading(true)
            getResponseFromCache(apiResponse, order_id, initials)?setApiError(false):setApiError(true)
            setOmniResponse(apiResponse)
            setLoading(false)
        }
        ls.flush();
    },[])
    return (
        <LoadingOverlay
        active={loading}
        spinner
        text='Loading your content...'
      >
        <Layout>
            {apiError && 
           <Alert variant="danger">
           Something went wrong please try again later
         </Alert>
        }   
            {omniResponse &&
            <>
                <Card >
                <Row>
                    <Col><b>Order Number:</b>  {omniResponse.data.order.order_reference_id}</Col> 
                    
                </Row>
                <Row>
                    <Col><b>Pick UP:</b>  {convertDate(omniResponse)}</Col> 
                    
                </Row>
                <Row>
                    <Col><b>Method:</b>   { (omniResponse.data.order.order_type == 'BOPIS' ||  omniResponse.data.order.order_type == 'BALLOON-PICKUP')? 'In Store' : 'Curbside'}</Col> 
                    
                </Row>
                
            </Card>
            
        
            <b/>
           
                <Card >
                    <Row>
                    <b>{omniResponse.data.store.store_name} Location </b>
                    </Row>
                <Row>
                    <Col>{omniResponse.data.store.street_1}</Col> 
                    
                </Row>
                <Row>
                    <Col>{`${camelCase(omniResponse.data.store.city, {pascalCase: true})}  ${omniResponse.data.store.province} ${omniResponse.data.store.postal_code}`}</Col> 
                    
                </Row>
                <Row>
                    <Col>{omniResponse.data.store.phone_number} </Col> 
                    
                </Row>
                
            </Card>
            
           
            <p>Let us know when you are here!</p>
            <Row>
            <Col>
            <Button onClick={handleBack}>Back</Button>
            
           <Button style={{marginLeft:"50px"}} onClick={handleIamHere}>I am here</Button>
            </Col>
                </Row>
                </>
            }
        </Layout>
            
    </LoadingOverlay>    
    );
};

export default OnMyWay;