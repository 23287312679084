import React, {useEffect} from "react";
import {Card, Col, Row} from "react-bootstrap";
import partycity_logo from './../images/partycity_logo.png'

const PageHeader = (props) => {


    useEffect(async ()=> {
    },[])

    return (
        <Card className="page-header">
            <Row>
                <Col className="col-sm-12">
                    <img src={partycity_logo} style={{width: "190px", height: "56px"}} />
                    <br/>
                    <span id="header-text">{props.headerText}</span>
                </Col>
            </Row>
        </Card>
    );
};

export default PageHeader;