import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import {Row, Col, Alert, Card, Image} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import ls from 'localstorage-slim';
import Layout from './Layout';
import './../microsite.css'
import './../reschedule.css'
import {
    useLocation, useNavigate
} from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay-ts';
import PageHeader from "./PageHeader";
import ErrorPage from "./ErrorPage";
import RescheduleDeliveryTimer from "./RescheduleDeliveryTimer";
import moment from "moment";
import {post} from "axios";

Modal.setAppElement('#root');


const RescheduleDelivery = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const orderParam = new URLSearchParams(search).get('o')
    const order_id = orderParam.substring(2,orderParam.length);
    const initials = orderParam.substring(0,2);
    const auth = new URLSearchParams(search).get('auth');
    const order_type = new URLSearchParams(search).get('t');
    const application_id = new URLSearchParams(search).get('ApplicationID');
    const user_name = new URLSearchParams(search).get('UserName');
    const user_role = new URLSearchParams(search).get('UserRole');
    const cb = new URLSearchParams(search).get('cb');
    const team = new URLSearchParams(search).get('team');
    const [errorMessage, setErrorMessage] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [omniResponse, setOmniResponse] = useState(false)
    const [currentRescheduleStatus, setCurrentRescheduleStatus] = useState("")
    const [currentRescheduleError, setCurrentRescheduleError] = useState("")
    const [unrecoverable, setUnrecoverable] = useState(false)
    const [pauseCounter, setPauseCounter] = useState(0);
    const [resetCounter, setResetCounter] = useState(1);
    const [pickupDTTM, setPickupDTTM] = useState(false)
    const [satisfied, setSatisfied] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isExpired, setIsExpired] = useState(false);
    const [modal, setModal] = useState(false);
    const [dateMatrix, setDateMatrix] = useState([])
    const [timeMatrix, setTimeMatrix] = useState([])
    const [monthYearMatrix, setMonthYearMatrix] = useState([])
    const [hasPrevious, setHasPrevious] = useState(false)
    const [hasNext, setHasNext] = useState(false)
    const [selectedMonthYear, setSelectedMonthYear] = useState(false)
    const [deliveryInstructions, setDeliveryInstructions] = useState(false)
    const [currentDate, setCurrentDate] = useState(false)
    const [selectedDate, setSelectedDate] = useState(false)
    const [selectedTime, setSelectedTime] = useState(false)
    const [leadTimeDisplay, setLeadTimeDisplay] = React.useState(false);
    const [week1, setWeek1] = useState([])
    const [week2, setWeek2] = useState([])
    const [week3, setWeek3] = useState([])
    const [week4, setWeek4] = useState([])
    const [week5, setWeek5] = useState([])
    const [week6, setWeek6] = useState([])


    useEffect(async ()=> {

        if (team !== "omni") {
            //setErrorMessage("You are not authorized to use this application");
            //setApiError(true);
            //return;
        }

        if (!omniResponse) {
            const body={"action":"get_delivery_windows","o":orderParam,"t": "D", "application_id": application_id,"user_name": user_name,"user_role": user_role, "auth": auth}
            setLoading(true)
            const headers = {
                'x-api-key': process.env.REACT_APP_API_KEY
            };
            try {
                const res = await post(`${process.env.REACT_APP_DELIVERY_ORDER_API_URL}/delivery_windows`, body, {headers})
                console.log(res);
                if (typeof res.data.available_delivery_dttms !== "undefined") {
                    const moment = require('moment-timezone');
                    moment.tz.setDefault(res.data.tz);
                    setOmniResponse(res.data)
                    const dt = moment(res.data.order_details.scheduled_dttm);
                    const formatted = dt.format("ddd, MMM D") + " at " + dt.format("h:mma");
                    setPickupDTTM(formatted);
                    init(res.data.available_delivery_dttms)
                } else {
                    if (typeof res.data.error == "undefined") {
                        setErrorMessage("Network issue")
                    } else {
                        setErrorMessage(res.data.error)
                    }
                    setApiError(true)
                }
            } catch (e) {
                setErrorMessage("Network issue")
                setApiError(true)
            }

        }

        setLoading(false)

        ls.flush();
    },[])

    const isFlowSatisfied = () => {
        const date = ls.get(`selectedDate`);
        const time = ls.get(`selectedTime`);
        const delivery_instructions = ls.get(`deliveryInstructions`);
        let result = true;
        if (( date == null || date == "" || time == null || time == "" ) && (delivery_instructions == "" || delivery_instructions == null || delivery_instructions == omniResponse.order_details.delivery_instructions)) {
            result = false;
        }
        setSatisfied(result)
    }

    const init = data => {
        const keys = Object.keys(data);
        const first_date = keys[0];
        const first_date_dt = moment(first_date);
        const first_month_year = first_date_dt.format("MMMM YYYY");
        buildDateMatrix(first_month_year);
        const month_year_matrix = [];
        month_year_matrix.push(first_month_year);
        setSelectedMonthYear(first_month_year);
        for(let key in keys) {
            let date_dt = moment(keys[key]);
            let month_year = date_dt.format("MMMM YYYY");
            if (month_year_matrix.indexOf(month_year) === -1) {
                month_year_matrix.push(month_year);
            }
        }
        if (month_year_matrix.length > 1) {
            setHasNext(true);
        }
        setMonthYearMatrix(month_year_matrix);
    }

    const nextMonthYear = () => {
        if (!hasNext) {
            return;
        }

        let index = monthYearMatrix.indexOf(selectedMonthYear);
        index++;
        setHasPrevious(true);
        const length = monthYearMatrix.length - 1;
        if (index === length) {
            setHasNext(false);
        }
        setSelectedMonthYear(monthYearMatrix[index]);
        buildDateMatrix(monthYearMatrix[index]);
    }

    const previousMonthYear = () => {
        if (!hasPrevious) {
            return;
        }

        let index = monthYearMatrix.indexOf(selectedMonthYear);
        index--;
        setHasNext(true);
        if (index == 0) {
            setHasPrevious(false);
        }
        setSelectedMonthYear(monthYearMatrix[index]);
        buildDateMatrix(monthYearMatrix[index]);
    }

    const selectDate = e => {
        const date = e.target.dataset.date;
        const status = e.target.dataset.status;
        if (status !== "available") {
            return;
        }
        setCurrentDate(date);
        buildTimeMatrix(date);
        setTimeout(function() {
            const el = document.getElementById("select-time-header");
            el.scrollIntoView(true);
        }, 200);
    }

    const selectTime = e => {
        const time = e.target.dataset.time;
        const status = e.target.dataset.status;
        if (status !== "available") {
            return;
        }
        setSelectedTime(time);
        setSelectedDate(currentDate);
        ls.set(`selectedTime`, time);
        ls.set(`selectedDate`, currentDate);
        isFlowSatisfied();
    }

    const buildDateMatrix = month_year => {
        const month_year_array = month_year.split(" ");
        const first_day = month_year_array[0] + " 01 " + month_year_array[1] + " 12:00";
        const first_day_dttm = moment(first_day);
        const first_day_week = first_day_dttm.clone().startOf("week");
        const current_pointer = first_day_week.clone();
        const last_day_month = first_day_dttm.clone().endOf("month");
        const last_day_calendar = last_day_month.clone().endOf("week");
        let fuse_blown = false;
        const date_matrix = [];
        const week1_matrix = [];
        const week2_matrix = [];
        const week3_matrix = [];
        const week4_matrix = [];
        const week5_matrix = [];
        const week6_matrix = [];
        let pointer = 0;
        while(!fuse_blown) {
            if (current_pointer.format("YYYY-MM-DD") == last_day_calendar.format("YYYY-MM-DD")) {
                fuse_blown = true;
            }
            date_matrix.push(current_pointer.format("YYYY-MM-DD"));
            if (pointer >= 35) {
                week6_matrix.push(current_pointer.format("YYYY-MM-DD"));
            }
            else if (pointer >= 28) {
                week5_matrix.push(current_pointer.format("YYYY-MM-DD"));
            }
            else if (pointer >= 21) {
                week4_matrix.push(current_pointer.format("YYYY-MM-DD"));
            }
            else if (pointer >= 14) {
                week3_matrix.push(current_pointer.format("YYYY-MM-DD"));
            }
            else if (pointer >= 7) {
                week2_matrix.push(current_pointer.format("YYYY-MM-DD"));
            }
            else {
                week1_matrix.push(current_pointer.format("YYYY-MM-DD"));
            }
            current_pointer.add(1, "days");
            pointer++;
        }
        setDateMatrix(date_matrix);
        setWeek1(week1_matrix);
        setWeek2(week2_matrix);
        setWeek3(week3_matrix);
        setWeek4(week4_matrix);
        setWeek5(week5_matrix);
        setWeek6(week6_matrix);
    }

    const buildTimeMatrix = date => {
        if (Object.keys(omniResponse.available_delivery_dttms).indexOf(date) === -1) {
            return;
        }
        const date_times = omniResponse.available_delivery_dttms[date];
        const date_time_start = date + " " + date_times.start;
        const date_time_end = date + " " + date_times.end;
        const start_dttm = moment(date_time_start);
        const end_dttm = moment(date_time_end);
        const current_pointer = start_dttm.clone();
        let fuse_blown = false;
        const time_matrix = [];
        while (!fuse_blown) {
            let current_pointer_end = current_pointer.clone().add((date_times.window), "minutes");
            let current_pointer_end_display = current_pointer.clone().add((date_times.window), "minutes");
            if (current_pointer_end.isAfter(end_dttm)) {
                fuse_blown = true;
                continue;
            }
            let data = {
                "start": current_pointer.format("HH:mm"),
                "end": current_pointer_end_display.format("HH:mm"),
                "date": date,
                "status": (date_times.start_time_blocked.indexOf(current_pointer.format("HH:mm")) !== -1) ? "unavailable" : "available"
            };
            time_matrix.push(data);
            current_pointer.add(date_times.window, "minutes");
        }
        setTimeMatrix(time_matrix)
    }

    const reschedule = async () => {
        if (!satisfied) {
            return;
        }
        if (!omniResponse.eligible_changes.scheduled_dttm && !omniResponse.eligible_changes.delivery_instructions) {
            return;
        }
        const pause_increment = pauseCounter + 1;
        setPauseCounter(pause_increment);
        let scheduled_dttm = false;
        if (selectedTime !== false) {
            scheduled_dttm = selectedDate + " " + selectedTime;
        }
        const delivery_instructions = deliveryInstructions;
        const body={"action":"reschedule_delivery","o":orderParam,"t": order_type, "application_id": application_id,"user_name": user_name,"user_role": user_role, "scheduled_dttm": scheduled_dttm, "delivery_instructions": delivery_instructions, "current_reschedule_status": currentRescheduleStatus}
        setLoading(true)
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        const res = await post(`${process.env.REACT_APP_DELIVERY_ORDER_API_URL}/reschedule_delivery`, body, {headers})
        if (res.data.success) {
            updateCache(scheduled_dttm);
            window.location.href = decodeURIComponent(cb);
        } else {
            setCurrentRescheduleError(res.data.error);
            if (typeof res.data.current_reschedule_status !== "undefined") {
                setCurrentRescheduleStatus(res.data.current_reschedule_status);
            } else {
                setCurrentRescheduleStatus("");
            }
            if (res.data.retry) {
                setUnrecoverable(false);
            } else {
                setUnrecoverable(true);
            }
            setModal(true);
            const reset_increment = resetCounter + 1;
            setResetCounter(reset_increment);
        }
        setLoading(false)
    }

    const sendEmailAlert = async() => {
        const body={"action":"send_delivery_email_alert","o":orderParam,"t": "D", "application_id": application_id,"user_name": user_name,"user_role": user_role, "current_reschedule_status": currentRescheduleStatus}
        setLoading(true)
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        try {
            const res = await post(`${process.env.REACT_APP_DELIVERY_ORDER_API_URL}/send_delivery_email_alert`, body, {headers})
        } catch (e) {

        }
        return;
    }

    const closeModal = async () => {
        setModal(false);
        await sendEmailAlert();
        back();
    }

    const retry = () => {
        reschedule();
    }

    const back = () => {
        window.location.href = decodeURIComponent(cb);
    }

    const getOffset = id => {
        const el = document.getElementById(id);
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    const toggleLeadTimeRules = () => {
        (leadTimeDisplay) ? setLeadTimeDisplay(false) : setLeadTimeDisplay(true);
    }

    const handleDeliveryInstructionsChange = e => {
        if (!omniResponse.eligible_changes.delivery_instructions) {
            return;
        }
        let value = e.target.value;
        if (value.length > 280) {
            value = value.substring(0, 280);
        }
        e.target.value = value;
        e.target.innerHTML = value;
        setDeliveryInstructions(value);
        ls.set(`deliveryInstructions`, value);
        isFlowSatisfied();
    }

    const updateCache = new_scheduled_dttm => {
        if (application_id == "OMNI") {
            ls.remove(`orders_${order_id}`);
            ls.remove(`store_${order_id}`);
            ls.remove(`customer_${order_id}`);
        }
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout>
                {(apiError || isExpired) &&
                <>
                    <ErrorPage isExpired={true} order_id={false} store_name={false} pickedup_time={false} isCancelled={false} isMixedOrder={false} errorMessage={errorMessage} genericError={true} customHeader="Update Delivery"/>
                    <button className="reschedule-cancel-error"  onClick={back}>Cancel</button>
                </>
                }

                {(!apiError) &&
                <>

                    <PageHeader
                        headerText="Update Delivery"
                    />
                    <br style={{clear: "both"}}/>
                    <br style={{clear: "both"}}/>
                    <br style={{clear: "both"}}/>
                    <br style={{clear: "both"}}/>

                    <RescheduleDeliveryTimer
                        pause={pauseCounter}
                        reset={resetCounter}
                        cb={cb}
                        modalOpen={modal}
                        orderParam={orderParam}
                        application_id={application_id}
                        user_name={user_name}
                        user_role={user_role}
                        currentRescheduleStatus={currentRescheduleStatus}
                        api_key={process.env.REACT_APP_API_KEY}
                        email_url={process.env.REACT_APP_ORDER_API_URL}
                    />

                    <div id="order_details">
                        {
                            omniResponse !== false &&
                            <>
                                <div className="container">
                                    <Row>
                                        <div className="col-12">
                                            <div className="order-details-data" style={{fontSize: "18px"}}>{omniResponse.order_details.customer_name}</div>
                                            <div className="order-details-data">Order: {omniResponse.order_details.order_reference_id}</div>
                                            <div className="order-details-data">Delivery: {pickupDTTM}</div>
                                            <div className="order-details-data">Carrier: {omniResponse.delivery_vendor_name}</div>
                                            <div className="order-details-data">Status: {omniResponse.status}</div>
                                            <div className="order-details-data"># of Balloons: {omniResponse.balloon_count}</div>
                                            <div className="order-details-data"># of Drivers: {omniResponse.number_of_drivers}</div>
                                        </div>
                                    </Row>
                                </div>
                            </>
                        }
                    </div>

                    <br/>
                    {
                        (application_id == "Store") &&
                            <>
                                <div id='delivery_instructions_container'>
                                    {
                                        typeof omniResponse.order_details !== "undefined" &&
                                        <>
                                            {
                                                !omniResponse.eligible_changes.delivery_instructions &&
                                                <>
                                                    <strong>Delivery Instructions:</strong>
                                                </>
                                            }
                                            {
                                                omniResponse.eligible_changes.delivery_instructions &&
                                                <>
                                                    <strong>Update/Enter Delivery Instructions</strong>&nbsp;&nbsp;<span style={{
                                                    fontSize: "7pt",
                                                    fontWeight: "bold"
                                                }}>(optional)</span><strong>:</strong>
                                                </>
                                            }
                                        </>
                                    }
                                    {
                                        typeof omniResponse.order_details !== "undefined" &&
                                        <>
                                <textarea id='delivery_instructions' disabled={(!omniResponse.eligible_changes.delivery_instructions) ? "disabled" : ""} onChange={handleDeliveryInstructionsChange}>
                                           {
                                               omniResponse.order_details.delivery_instructions
                                           }
                                </textarea>
                                        </>
                                    }
                                    {
                                        typeof omniResponse.order_details == "undefined" &&
                                        <>
                                            <textarea id='delivery_instructions' disabled="disabled"></textarea>
                                        </>
                                    }
                                    <span id='text_area_subtext'>Up to 280 characters</span>
                                </div>
                                <br/>
                            </>
                    }



                    {
                        typeof omniResponse.order_details !== "undefined" &&
                        <>

                            {
                                (!omniResponse.eligible_changes.scheduled_dttm && !omniResponse.eligible_changes.delivery_instructions) &&
                                <>
                                    <p  style={{color:"red",fontWeight:"bold",textAlign:"center",textDecoration:"underline",fontSize:"18px"}}>This order is not eligible to be changed</p>
                                </>
                            }

                            {
                                (!omniResponse.eligible_changes.scheduled_dttm && omniResponse.eligible_changes.delivery_instructions && application_id == "Store") &&
                                <>
                                    <p style={{color:"red",fontWeight:"bold",textAlign:"center",textDecoration:"underline",fontSize:"18px"}}>Only delivery instructions can be changed for this order</p>
                                </>
                            }

                            {
                                (omniResponse.eligible_changes.scheduled_dttm) &&
                                <>
                                    <p onClick={toggleLeadTimeRules} style={{color:"red",fontWeight:"bold",textAlign:"center",textDecoration:"underline",fontSize:"18px"}}>Balloon Lead Time Best Practices</p>
                                </>
                            }
                        </>
                    }
                    {
                        leadTimeDisplay &&
                        <>
                            <div id="lead_time_display_container">
                                <h5 style={{textAlign:"center",fontWeight:"bold"}}>Balloon lead time rules:</h5>
                                <br/>
                                <ol>
                                    <li>Order must be rescheduled at least 6 hours in advance of original pickup time.</li>
                                    <li>Orders cannot be rescheduled beyond 10 days of the original pickup date.</li>
                                    <li>Larger balloon orders require additional lead time.
                                        <ul>
                                            <li>1-95 balloons: +2 hour lead time</li>
                                            <li>96-249 balloons: +8 hour lead time</li>
                                            <li>250-499 balloons: +24 hour lead time</li>
                                            <li>500+ balloons: +48 hour lead time</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Confirm customer celebration time.
                                        <ul>
                                            <li>Regular, 12-inch latex balloons will float for around 10 hours when inflated with only helium.</li>
                                            <li>Foil balloons, however, will float for 5-7 days.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                            <br/>
                        </>
                    }
                    {
                        typeof omniResponse.order_details == "undefined" &&
                        <>

                        </>
                    }
                    {
                        typeof omniResponse.order_details !== "undefined" &&
                        <>
                            <div id="reschedule_container" data-active={(!omniResponse.eligible_changes.scheduled_dttm) ? "false" : "true"}>

                                <h5 style={{fontFamily:"futuraptbold"}}>Select a new date:</h5>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-4" style={{padding:"0px"}}>
                                            {
                                                hasPrevious &&
                                                <>
                                                    <span onClick={previousMonthYear} className="previous-arrow">&lt;</span>
                                                </>
                                            }
                                        </div>
                                        <div className="col-4">
                                            <span className="selected-month-year">{selectedMonthYear}</span>
                                        </div>
                                        <div className="col-4" style={{padding:"0px"}}>
                                            {
                                                hasNext &&
                                                <>
                                                    <span onClick={nextMonthYear} className="next-arrow">&gt;</span>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className="container date_picker_container">
                                    <Row>
                                        <div className="col-1 d-flex futura flex-fill date_bubble_container">Sun</div>
                                        <div className="col-1 d-flex futura flex-fill date_bubble_container">Mon</div>
                                        <div className="col-1 d-flex futura flex-fill date_bubble_container">Tue</div>
                                        <div className="col-1 d-flex futura flex-fill date_bubble_container">Wed</div>
                                        <div className="col-1 d-flex futura flex-fill date_bubble_container">Thu</div>
                                        <div className="col-1 d-flex futura flex-fill date_bubble_container">Fri</div>
                                        <div className="col-1 d-flex futura flex-fill date_bubble_container">Sat</div>
                                    </Row>
                                    <br/>
                                    <Row>
                                        {
                                            week1.map(date => {
                                                return <div className="col-1 d-flex futura flex-fill date_bubble_container"><div className="date_bubble"  onClick={selectDate} data-date={date} data-status={(moment(date).format("MMMM YYYY") !== selectedMonthYear) ? "hide" : (currentDate == date) ? "selected" : ((Object.keys(omniResponse.available_delivery_dttms).indexOf(date) === -1) || omniResponse.available_delivery_dttms[date] == "closed") ? "unavailable" : "available"}>{moment(date).format("D")}</div></div>
                                            })
                                        }
                                    </Row>
                                    <br/>
                                    <Row>
                                        {
                                            week2.map(date => {
                                                return <div className="col-1 d-flex futura flex-fill date_bubble_container"><div className="date_bubble"  onClick={selectDate} data-date={date} data-status={(moment(date).format("MMMM YYYY") !== selectedMonthYear) ? "hide" : (currentDate == date) ? "selected" : ((Object.keys(omniResponse.available_delivery_dttms).indexOf(date) === -1) || omniResponse.available_delivery_dttms[date] == "closed") ? "unavailable" : "available"}>{moment(date).format("D")}</div></div>
                                            })
                                        }
                                    </Row>
                                    <br/>
                                    <Row>
                                        {
                                            week3.map(date => {
                                                return <div className="col-1 d-flex futura flex-fill date_bubble_container"><div className="date_bubble"  onClick={selectDate} data-date={date} data-status={(moment(date).format("MMMM YYYY") !== selectedMonthYear) ? "hide" : (currentDate == date) ? "selected" : ((Object.keys(omniResponse.available_delivery_dttms).indexOf(date) === -1) || omniResponse.available_delivery_dttms[date] == "closed") ? "unavailable" : "available"}>{moment(date).format("D")}</div></div>
                                            })
                                        }
                                    </Row>
                                    <br/>
                                    <Row>
                                        {
                                            week4.map(date => {
                                                return <div className="col-1 d-flex futura flex-fill date_bubble_container"><div className="date_bubble"  onClick={selectDate} data-date={date} data-status={(moment(date).format("MMMM YYYY") !== selectedMonthYear) ? "hide" : (currentDate == date) ? "selected" : ((Object.keys(omniResponse.available_delivery_dttms).indexOf(date) === -1) || omniResponse.available_delivery_dttms[date] == "closed") ? "unavailable" : "available"}>{moment(date).format("D")}</div></div>
                                            })
                                        }
                                    </Row>
                                    <br/>
                                    <Row>
                                        {
                                            week5.map(date => {
                                                return <div className="col-1 d-flex futura flex-fill date_bubble_container"><div className="date_bubble"  onClick={selectDate} data-date={date} data-status={(moment(date).format("MMMM YYYY") !== selectedMonthYear) ? "hide" : (currentDate == date) ? "selected" : ((Object.keys(omniResponse.available_delivery_dttms).indexOf(date) === -1) || omniResponse.available_delivery_dttms[date] == "closed") ? "unavailable" : "available"}>{moment(date).format("D")}</div></div>
                                            })
                                        }
                                    </Row>
                                    <br/>
                                    <Row>
                                        {
                                            week6.map(date => {
                                                return <div className="col-1 d-flex futura flex-fill date_bubble_container"><div className="date_bubble"  onClick={selectDate} data-date={date} data-status={(moment(date).format("MMMM YYYY") !== selectedMonthYear) ? "hide" : (currentDate == date) ? "selected" : ((Object.keys(omniResponse.available_delivery_dttms).indexOf(date) === -1) || omniResponse.available_delivery_dttms[date] == "closed") ? "unavailable" : "available"}>{moment(date).format("D")}</div></div>
                                            })
                                        }
                                    </Row>
                                    {
                                        week6.length > 0 &&
                                        <>
                                            <br/>
                                        </>
                                    }
                                    <Row>
                                        <div className="col-4 legend_container available_legend"><div className="date_bubble_legend" data-type="available"></div> <span className="legend_text">Available</span></div>
                                        <div className="col-4 legend_container unavailable_legend"><div className="date_bubble_legend" data-type="unavailable"></div> <span className="legend_text">Unavailable</span></div>
                                        <div className="col-4 legend_container selected_legend"><div className="date_bubble_legend" data-type="selected"></div> <span className="legend_text">Selected</span></div>
                                    </Row>
                                </div>

                                <hr/>

                                <h5 style={{fontFamily:"futuraptbold"}} id="select-time-header">Select a new time:</h5>

                                <div className="container">
                                    <Row>
                                        {
                                            timeMatrix.map(time => {
                                                return <div className="col-6"><div onClick={selectTime} data-time={time.start} data-status={((time.date == selectedDate && time.start == selectedTime) && (selectedDate == currentDate)) ? "selected" : time.status}  className="time-slot">{moment((moment().format("YYYY-MM-DD") + " " + time.start)).format("h:mm A").replace(":00","") + " - " + moment((moment().format("YYYY-MM-DD") + " " + time.end)).format("h:mm A").replace(":00","")}</div></div>
                                            })
                                        }
                                    </Row>
                                </div>
                            </div>
                        </>
                    }

                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <Card className="page-footer page-footer-reschedule">
                        <Row>
                            <Col className="col-sm-12">
                                {
                                    typeof omniResponse.order_details !== "undefined" &&
                                    <>
                                        {
                                            (!omniResponse.eligible_changes.scheduled_dttm && !omniResponse.eligible_changes.delivery_instructions) &&
                                                <>
                                                    <Button className="back-button reschedule-cancel" style={{width:"42vw", textAlign:"center",marginLeft:"0px"}} onClick={back}>Cancel</Button>
                                                </>
                                        }
                                        {
                                            (omniResponse.eligible_changes.scheduled_dttm || omniResponse.eligible_changes.delivery_instructions) &&
                                            <>
                                                <Button className="continue-button reschedule-continue" style={{width:"42vw"}} data-disabled={(satisfied) ? "false" : "true"} onClick={reschedule}>Save</Button>
                                                <Button className="back-button reschedule-cancel" style={{width:"42vw"}} onClick={back}>Cancel</Button>
                                            </>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                    </Card>

                    <Modal
                        isOpen={modal}
                    >
                        <p style={{textAlign:"center",fontSize:"16px", padding:"10px"}}>
                            <h2>Failure To Reschedule Delivery Order</h2>
                            <span style={{color:"red"}}>{currentRescheduleError}</span>
                        </p>
                        <br/>
                        <br/>
                        <Row>
                            <Col className="col-sm-12" style={{textAlign:"center"}}>
                                {
                                    !unrecoverable &&
                                    <>
                                        <button className="retryButton continue-button" onClick={retry} style={{marginRight: "5px", width: "42%"}}>Retry</button>
                                    </>
                                }
                                <button className="closeModal back-button" onClick={closeModal} style={{marginLeft: "5px", width: "42%"}}>Cancel</button>
                            </Col>
                        </Row>
                    </Modal>

                </>
                }

            </Layout>
        </LoadingOverlay>
    );
};

export default RescheduleDelivery;