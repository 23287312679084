import React from 'react';
import {Row, Container, Col} from 'react-bootstrap'
import MetaTags from 'react-meta-tags';
import Bootstrap from 'bootstrap/dist/css/bootstrap.css';
<script
  src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
  crossorigin></script>
const Layout = (props) => {
    return (
      <div className="overflow-hidden">
        <MetaTags>
            <title>Party City</title>
            <meta name="description" content="Some description." />
            <meta property="og:title" content="MyApp" />
            <meta property="og:image" content="path/to/image.jpg" />
        </MetaTags>
        <Container  fluid style={{
            padding: "10px",
            justifyContent: props.align,
            alignItems: props.align,
            textAlign: props.align,
            
          }}>
            {props.children}
        </Container>
      </div>
    );
};

export default Layout;