import React, { useState } from 'react';
import {Row, Col, Modal, Alert, Card, Image} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Layout from './Layout';
import  p from './../images/icons/p.svg'
import  a from './../images/icons/a.svg'
import  r from './../images/icons/r.svg'
import  t from './../images/icons/t.svg'
import  y from './../images/icons/y.svg'
import  c from './../images/icons/c.svg'
import  i from './../images/icons/i.svg'
import  t1 from './../images/icons/t1.svg'
import  y1 from './../images/icons/y1.svg'

import './../microsite.css'
import ReactStars from "react-rating-stars-component";
const FeedBack = () => {
    const [feedback, setFeedback] = useState('')
    const ratingChanged = (newRating) => {
        console.log(newRating);
      };
    const submitFeedback = () =>{
        window.close();
    }
    return (
        <Layout align="center">
        <Card style={{textAlign:"center"}}>
        <div style={{textAlign:"center"}}>
          <Image src={p}></Image>
          <Image src={a}></Image>
          <Image src={r}></Image>
          <Image src={t}></Image>
          <Image src={y}></Image>
          <Image src={c}></Image>
          <Image src={i}></Image>
          <Image src={t1}></Image>
          <Image src={y1}></Image>
         
        </div>
        <div style={{textAlign:"center", fontFamily:'futuraptbold'}} ><b>Party Pickup</b></div>
        
      </Card>
      <div style={{textAlign:"center"}}>
          <br/>
          <div><b>How would you rate your experience today?</b></div>
          <div style={{textAlign:"center"}}>
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={35}
            isHalf={true}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
            
            />
            <br/>
            <div style={{textAlign:"center"}}>
            Please feel free to leave us any comments or feedback about your experience today.
            <br/>
            <textarea className="feedbacktextarea" 
            name="feedback"
            id="feedback"
            data-tid="avy"
            onChange={(e) => setFeedback(e.target.value)}/>
            </div>
            </div >
            <Button className="submitfeedback" onClick={submitFeedback}>Submit</Button>
            <br/>
            <Button variant="link" onClick={submitFeedback}>Close Window</Button>
      </div>
            
        </Layout>
    );
};

export default FeedBack;