import {Button, Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ls from "localstorage-slim";
import {useLocation, useNavigate} from "react-router";

const VehicleFlowFooter = (props) => {

    const navigate = useNavigate();
    const search = useLocation().search;
    const orderParam = new URLSearchParams(search).get('o')
    const order_id = orderParam.substring(2,orderParam.length);
    const initials = orderParam.substring(0,2);
    const shipment_id = new URLSearchParams(search).get('s');
    const order_type = new URLSearchParams(search).get('t');
    const currentPage = ls.get(`currentPage_${shipment_id}`)
    const setVehicleInfoToOMNI = () => {
        let shipment=ls.get(`shipment_${shipment_id}`)
        let CurrentorderType = shipment.order_type  == 'BOPIS' ||  shipment.order_type  == 'BALLOON-PICKUP'? 'IS' : 'CS'
        const body={
                    "action":"i_am_here",
                    "o":orderParam,
                    "s": shipment_id,
                    "t": order_type,
                    "vehicle_type": ls.get('vehicleType'),
                    "vehicle_color": ls.get('vehicleColor'),
                    "vehicle_make": ls.get('carMakeModel'),
                    "bag_location": ls.get('bagLocation'),
                    "parking_location": props.parkingSpaceInfo,
                    "CurrentorderType": CurrentorderType
                }
        fetch(`${process.env.REACT_APP_ORDER_API_URL}/iamhere`, {  // Enter your IP address here
    
          method: 'POST', 
          mode: 'cors',
          headers: {
            'x-api-key': process.env.REACT_APP_API_KEY
        }, 
          body: JSON.stringify(body) // body data type must match "Content-Type" header
    
        })
    }

    const updateVehicleDetailsToOMS =  () => {
        let customer = ls.get(`customer_${order_id}`)
        let email=customer.email_address
        let customerContactKey= ls.get(`CustomerContactKey_${email}`) || ''
        let customerKey = ls.get(`CustomerKey_${email}`)|| ''
        let orgCode = ls.get(`OrganizationCode_${email}`)|| 'PARTYCITY'
        let customerType = ls.get(`CustomerType_${email}`)|| '02'
        const body={
            "Customer": [
                {
                    "CustomerContactList": {
                        "CustomerContact": [
                            {
                                "CustomerContactKey": customerContactKey,
                                "EmailID": email,
                                "Extn": {
                                    "ExtnVehicleMake": ls.get(`carMakeModel`),
                                    "ExtnVehicleType": ls.get(`vehicleType`),
                                    "ExtnAvaTaxExemptNo": "",
                                    "ExtnPackagePlacementPreference": ls.get(`bagLocation`),
                                    "ExtnVehicleColor": ls.get(`vehicleColor`)
                                }
                            }
                        ]
                    },
                    "CustomerKey": customerKey,
                    "OrganizationCode": orgCode,
                    "CustomerType": customerType
                }
            ]
        }
    fetch(`${process.env.REACT_APP_ORDER_API_URL}/vehicle_details`, {  // Enter your IP address here

    method: 'POST', 
    mode: 'cors', 
    headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
    },
    body: JSON.stringify(body)// body data type must match "Content-Type" header

    })

}
    const handleBack = () => {
        const previous = getPreviousPage();
        navigate(`/${previous}${search}`)
        ls.set(`currentPage_${shipment_id}`,previous)
    }

    const handleNext = () => {
        if (props.satisfied) {
            if(currentPage==="parkinginfo")setVehicleInfoToOMNI()
            if(currentPage==="baglocation")updateVehicleDetailsToOMS()
            const next = getNextPage();
            ls.set(`currentPage_${shipment_id}`,next)
            navigate(`/${next}${search}`)
        }
    }

    const getPreviousPage = () => {
        let previous = false;
        switch (currentPage) {
            case "vehicleinfo":
                previous = "microsite";
                break;
            case "baglocation":
                previous = "vehicleinfo";
                break;
            case "parkinginfo":
                previous = "baglocation";
                break;
        }
        return previous;
    }


    const getNextPage = () => {
        let next = false;
        switch (currentPage) {
            case "vehicleinfo":
                next = "baglocation";
                break;
            case "baglocation":
                next = "parkinginfo";
                break;
            case "parkinginfo":
                next = "iamhere";
                break;
        }
        return next;
    }

    useEffect(async ()=> {
    },[])


    return (
        <Card className="page-footer vehicle-footer">
            <Row>
                <Col className="col-sm-6">
                    <Button className="back-button" onClick={handleBack}>Back</Button>
                </Col>
                <Col className="col-sm-6">
                    <Button className="continue-button" data-disabled={(props.satisfied) ? "false" : "true"} onClick={handleNext}>Continue</Button>
                </Col>
            </Row>
        </Card>
    );
};

export default VehicleFlowFooter;