import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Moment from 'react-moment';
import moment from 'moment';
import {Row, Col, Card, Alert, Container,Image} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Layout from './Layout';
import {getResponseFromCache, setLocalStorage} from './../util/util.js'
import {get, post} from 'axios'
import {
    Link, useLocation, useNavigate
} from "react-router-dom";
//import apis from '../apis/apis'
import ls from 'localstorage-slim';
import map from './../images/icons/normal_location_on_u30.svg'
import phone from './../images/icons/phone_icon.svg'
import party_pickup from './../images/icons/MyPartyPickupIcon.svg'
import instore from './../images/icons/ICON_LIGHTBLUE-01.svg'
import curbside from './../images/icons/curbside.svg'
import  arrow_up from './../images/icons/arrow_up.svg'
import  arrow_down from './../images/icons/arrow_down.svg'
import  information_bubble from './../images/icons/infoButton.svg'
import  oos_information_bubble from './../images/icons/oos_information_bubble.svg'
import  order_status from './../images/icons/order_status.svg'
import  party_pickup_grey from './../images/icons/party_pickup_grey.svg'
import  party_pickup_green from './../images/icons/party_pickup_green.svg'
import  party_pickup_cancelled from './../images/icons/party_pickup_cancelled.svg'
import  balloons_green from './../images/icons/balloons_green.svg'
import  balloons_grey from './../images/icons/balloons_grey.svg'
import  second_contact from './../images/icons/second_contact.png'
import './../microsite.css'
import LoadingOverlay from 'react-loading-overlay-ts';
import PageHeader from "./PageHeader";
import ErrorPage from "./ErrorPage";
import StoreInformation from "./StoreInformation";
import IdleTimer, { useIdleTimer } from 'react-idle-timer/modern'
import UTag from "./UTag";
//test

Modal.setAppElement('#root');

const Microsite = () => {
    const navigate = useNavigate();
    let search = useLocation().search;
    let orderParam,order_id,initials,shipment_id,order_type,redirect, auth
    if(search){
        orderParam = new URLSearchParams(search).get('o')
        order_id = orderParam.substring(2,orderParam.length);
        initials = orderParam.substring(0,2);
        shipment_id = new URLSearchParams(search).get('s');
        order_type = new URLSearchParams(search).get('t');
        redirect = new URLSearchParams(search).get('redirect');
        auth = new URLSearchParams(search).get('auth');
    }
    const [orders, setOrders] = useState({})
    const [shipments, setShipments] = useState({})
    const [store, setStore] = useState({})
    const [customer, setCustomer] = useState({})
    const [apiError, setApiError] = useState(false)
    const [orderType, setOrderType]  = useState('')
    const [orderContent, setOrderContent]  = useState('')
    const [searchParam, setSearchParam] = useState(search)
    const [rescheduleURL, setRescheduleURL] = useState(false)
    const [cancelURL, setCancelURL] = useState(false)
    const [extendRestockURL, setExtendRestockURL] = useState(false)
    const [loading, setLoading] = useState(false)
    const [hg, setHG] = useState(false);
    const [hgPickedUp, setHGPickedUp] = useState(false);
    const [hgPickComplete, setHGPickComplete] = useState(false);
    const [balloonPickedUp, setBalloonPickedUp] = useState(false);
    const [balloonPickComplete, setBalloonPickComplete] = useState(false);
    const [balloon, setBalloon] = useState(false);
    const [hgOMW, setHGOMW] = useState(false);
    const [hgReady, setHGReady] = useState(false);
    const [hgCancelled, setHGCancelled] = useState(false);
    const [hgOOS, setHGOOS] = useState(false);
    const [balloonOMW, setBalloonOMW] = useState(false);
    const [balloonReady, setBalloonReady] = useState(false);
    const [balloonCancelled, setBalloonCancelled] = useState(false);
    const [balloonOOS, setBalloonOOS] = useState(false);
    const [pickedUpTimeHG, setPickedUpTimeHG] = useState(false);
    const [pickupTimeHG, setPickupTimeHG] = useState(false);
    const [pickupTimeBalloon, setPickupTimeBalloon] = useState(false);
    const [formattedPickupTime, setFormattedPickupTime] = useState(false);
    const [readyOMW, setReadyOMW] = useState(false);
    const [curbsideCutoff, setCurbsideCutoff] = useState(false);
    const [readyToProceed, setReadyToProceed] = useState(false);
    const [onMyWay, setOnMyWay] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [genericError, setGenericError] = useState(false);
    const [barCode, setBarCode] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false)
    const [isMixedOrder, setIsMixedOrder] = useState(false)
    const [firstTimeout, setFirstTimeout] = useState(false);
    const [idleTime, setIdleTime] = useState(3000);
    const [idleTimer, setIdleTimer] = useState(false);
    const [idleTimerFuse, setIdleTimerFuse] = useState(parseInt(process.env.REACT_APP_IDLE_TIMEOUT_FUSE));
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpenOOS, setOOSIsOpen] = React.useState(false);
    const [modalIsOpenOMW, setIsOpenOMW] = React.useState(false);
    const [modalIsOpenReschedule, setIsOpenReschedule] = React.useState(false);
    const [modalIsOpenRefresh, setIsOpenRefresh] = React.useState(false);
    const [modalIsOpenOrderNotReady, setIsOpenOrderNotReady] = React.useState(false);
    const [orderNotReadyVerbiage, setorderNotReadyVerbiage] = React.useState("");
    const [utagEvent, setUtagEvent] = React.useState(false);
    const [earlyCall, setEarlyCall] = React.useState(false);

    const isHG = async () => {
        let hg = false;
        let hgOMW = false;
        let hgPickedUp = false;
        let hgPickUpTime = false;
        let hgPickComplete = false;
        let hgCancelled = false;
        let hgOMSPickedUp = false;

        const order = ls.get( ( "orders_" + order_id ));
        const shipment_ids =  order.shipment;
        for (let key in shipment_ids) {
            let shipment = ls.get( ( "shipment_" + shipment_ids[key]  ) );
            if (shipment.order_type == "BOPIS" || shipment.order_type == "CURBSIDE") {
                hg = true;
                if (order.order_content == "HB") {
                    hgPickUpTime = shipment.scheduled_dttm;
                    setPickupTimeHG(shipment.scheduled_dttm);
                } else {
                    hgPickUpTime = moment(shipment.restock_date).set({"hour": 23, "minute": 59, "second": 59}).format("YYYY-MM-DD HH:mm");
                    setPickupTimeHG(hgPickUpTime);
                }
                setPickedUpTimeHG(shipment.picked_up_dttm);
                if (shipment.oms_picked_up) {
                    hgOMSPickedUp = true;
                }
                if (shipment.pick_complete) {
                    hgPickComplete = true;
                }
                if (shipment.status === "Picked Up") {
                    hgPickedUp = true;
                }
                if (shipment.status === "cancelled") {
                    hgCancelled = true;
                }
                setHGOOS(shipment.oos)
            }
        }
        hgOMW = (hgCancelled || hgPickedUp || !hgPickComplete) ? false : true;
        setHGOMW(hgOMW);
        setHGReady((hgCancelled  || hgPickedUp ||  !hgPickComplete) ? false : true);
        setHGPickedUp(hgPickedUp);
        setHGCancelled(hgCancelled);
        setHGPickComplete(hgPickComplete);
        setHG(hg);
        return {hg:hg,hgPickedUp:hgPickedUp,hgPickUpTime:hgPickUpTime,hgCancelled:hgCancelled,hgPickComplete:hgPickComplete, hgOMW: hgOMW,hgOMSPickedUp:hgOMSPickedUp}
    }

    const isBalloon = async () => {
        let balloon = false;
        let balloonOMW = false;
        let balloonReady = false;
        let balloonCancelled = false;
        let balloonPickedUp = false;
        let balloonOMSPickedUp = false;
        let balloonPickUpTime = false;
        let balloonPickComplete = false;

        const order = ls.get( ( "orders_" + order_id ));
        const shipment_ids =  order.shipment;
        for (let key in shipment_ids) {
            let shipment = ls.get( ( "shipment_" + shipment_ids[key] ) );
            if (shipment.order_type == "BALLOON-PICKUP" || shipment.order_type == "BALLOON-CURBSIDE") {
                balloon = true;
                if (shipment.status === "Picked Up") {
                    balloonPickedUp = true;
                }
                if (!moment().isBefore(moment(shipment.scheduled_dttm).subtract(15, "minutes"))) {
                    balloonOMW = true;
                    balloonReady = true;
                }
                if (shipment.oms_picked_up) {
                    balloonOMSPickedUp = true;
                }
                if (shipment.pick_complete) {
                    balloonPickComplete = true;
                } else {
                    balloonPickComplete = false;
                    balloonReady = false;
                }
                if (shipment.status === "cancelled") {
                    balloonCancelled = true;
                    balloonReady = false;
                }
                setBalloonOOS(shipment.oos)
                balloonPickUpTime = shipment.scheduled_dttm;
                setPickupTimeBalloon(shipment.scheduled_dttm);
            }
        }
        setBalloonOMW(balloonOMW);
        setBalloonReady(balloonReady);
        setBalloonCancelled(balloonCancelled);
        setBalloonPickedUp(balloonPickedUp);
        setBalloon(balloon);
        setBalloonPickComplete(balloonPickComplete);
        console.log(balloonReady)
        return {balloon:balloon,balloonReady:balloonReady,balloonPickedUp:balloonPickedUp,balloonPickUpTime:balloonPickUpTime,balloonCancelled:balloonCancelled,balloonPickComplete:balloonPickComplete,balloonOMW:balloonOMW,balloonOMSPickedUp:balloonOMSPickedUp}
    }

    const handleOnMyway = async () => {
        if (!readyOMW || onMyWay) {
            return;
        }
        const body={"action":"on_my_way","o":orderParam,"s": shipment_id,"t": order_type}
        setLoading(true)
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        const res = await post(`${process.env.REACT_APP_ORDER_API_URL}/order_details`, body, {headers})
        let shipment= ls.get(`shipment_${shipment_id}`)
        shipment.on_my_way = true
        ls.set(`shipment_${shipment_id}`, shipment)
        setLoading(false)
        setOnMyWay(true)
        openModalOMW();
    }

    const handleIamHere = async e  => {
        if (!readyToProceed) {
            return;
        }
        if (e.target.dataset.enabled == "false") {
            return;
        }
        const method = e.target.dataset.method;
        if (method == "CURBSIDE" && curbsideCutoff) {
            return;
        }
        setLoading(true)
        const body={"action":"i_am_here","o":orderParam,"s": shipment_id,"t": order_type, method: method}
        const headers = {'x-api-key': process.env.REACT_APP_API_KEY };

        let success = true;
        const res = await post(`${process.env.REACT_APP_ORDER_API_URL}/iamhere`, body, {headers}/*, { timeout: 2 * 60 * 1000 }*/)
        if (process.env.REACT_APP_ENV == "prod") {
            success = res.data.success;
        }
        if (success) {
            const shipment_ids = ls.get(`shipment_ids_${order_id}`);
            for (let key in shipment_ids) {
                let shipment_obj = ls.get(`shipment_${shipment_ids[key]}`);
                let order_type = shipment_obj.order_type;
                let new_order_type = false;
                switch (method) {
                    case "IN-STORE":
                        new_order_type = (order_type.indexOf("BALLOON") !== -1) ? "BALLOON-PICKUP" : "BOPIS";
                        break;
                    case "CURBSIDE":
                        new_order_type = (order_type.indexOf("BALLOON") !== -1) ? "BALLOON-CURBSIDE" : "CURBSIDE";
                        break;
                }
                shipment_obj.order_type = new_order_type;
                shipment_obj.picked_up_dttm = moment().format("YYYY-MM-DD HH:mm");
                if (shipment_obj.shipment_id == shipment_id) {
                    setShipments(shipment_obj);
                }
                ls.set(`shipment_${shipment_ids[key]}`, shipment_obj);
            }
            setLoading(false)
            let current_shipment = ls.get(`shipment_${shipment_id}`);
            if(current_shipment.order_type == 'BOPIS' ||  current_shipment.order_type == 'BALLOON-PICKUP'){
                ls.set(`currentPage_${shipment_id}`,"iamhereIS")
                navigate(`/iamhereIS${search}`)
            }else{
                ls.set(`currentPage_${shipment_id}`,"vehicleinfo")
                navigate(`/vehicleinfo${search}`)
            }
        } else {
            setLoading(false)
            alert("There was a problem submitting this request");
        }
    }

    const setCurrentOrderType = (currentOrderType) => {
        setOrderType((currentOrderType == 'BOPIS' ||  currentOrderType == 'BALLOON-PICKUP')? 'IS' : 'CS')
    }

    useEffect(async ()=> {
        if(search) {
            let orders = ls.get(`orders_${order_id}`)
            let customer = ls.get(`customer_${order_id}`)
            let store = ls.get(`store_${order_id}`)
            let shipments = ls.get(`shipment_${shipment_id}`)
            if (orders === null || shipments == null) {
                setLoading(true)
                let res = await setLocalStorage(search, order_id, shipment_id)
                console.log('res####', res)
                if (res) {
                    setOrders(res.orders)
                    setStore(res.store)
                    setCustomer(res.customer)
                    setShipments(res.shipment);
                    setLoading(false)
                } else {
                    setLoading(false)
                    setApiError(true)
                }
            } else {
                setLoading(true)
                getResponseFromCache(orders, shipments, customer, order_id, initials, shipment_id) ? setApiError(false) : setApiError(true)
                setOrders(orders)
                setCustomer(customer)
                setStore(store)
                setShipments(shipments);
                setLoading(false)
            }

            let currentPage = ls.get(`currentPage_${shipment_id}`)
            if (currentPage === null) {
                ls.set(`currentPage_${shipment_id}`, "microsite")
                currentPage = "microsite";
            } else {
                await navigate(`/${currentPage}${search}`)
            }

            let order = ls.get(`orders_${order_id}`)

            if (typeof order.error_message !== "undefined") {
                setErrorMessage(order.error_message)
                setGenericError(true)
                return;
            }

            let shipment = ls.get(`shipment_${shipment_id}`)
            setOnMyWay(shipment.on_my_way)
            let store_cache = ls.get(`store_${order_id}`)

            setOrderContent(order.order_content)

            const moment = require('moment-timezone');

            moment.tz.setDefault(store_cache.tz);

            const cutoff_time = moment().format("YYYY-MM-DD") + " 21:00:00";
            const cutoff_moment = moment(cutoff_time);
            if (moment().isAfter(cutoff_moment)) {
                setCurbsideCutoff(true);
            }

            setInterval(function() {
                const cutoff_time = moment().format("YYYY-MM-DD") + " 21:00:00";
                const cutoff_moment = moment(cutoff_time);
                if (moment().isAfter(cutoff_moment)) {
                    setCurbsideCutoff(true);
                }
            },3000);




            const today = store_cache.expanded_hours[0][moment().format("YYYY-MM-DD")];
            let store_status = false;
            if (typeof today.close !== "undefined") {
                setInterval(function() {
                    let close = moment().format("YYYY-MM-DD") + " " + today.close;
                    let close_dttm = moment(close);
                    if ((moment().format("HH:mm:ss") == (today.close + ":00")) || (moment().isAfter(close_dttm) && (moment().format("mm:ss") == "15:00" || moment().format("mm:ss") == "30:00" || moment().format("mm:ss") == "45:00" || moment().format("mm:ss") == "00:00"))) {
                        window.location.reload();
                    }
                }, 500);
            }

            if (today.status == "closed") {
                store_status = "closed";
            } else {
                store_status = moment().isBefore(moment(moment().format("YYYY-MM-DD") + " " + today.open).subtract(30, "minutes")) ?
                    "closed" :
                    moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + today.close).subtract(0, "minutes")) ?
                        "closed" :
                        moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + today.close).subtract(30, "minutes")) ?
                            "closing soon" :
                            moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + today.open).subtract(0, "minutes")) ?
                                "open" :
                                "opening soon";
            }

            const hgData = await isHG();
            const balloonData = await isBalloon();

            const domain = process.env.REACT_APP_DOMAIN;
            const protocol = process.env.REACT_APP_PROTOCOL;
            const port = process.env.REACT_APP_PORT;

            if (!!order.reschedule) {
                if (domain == "localhost") {
                    setRescheduleURL(`${protocol}://${domain}:${port}/reschedule?ApplicationID=OMNI&UserName=Customer&UserRole=Customer&o=${orderParam}&s=${shipment_id}&t=${order_type}&auth=${auth}&cb=${encodeURIComponent(window.location.href)}`);
                } else {
                    setRescheduleURL(`${protocol}://${domain}/reschedule?ApplicationID=OMNI&UserName=Customer&UserRole=Customer&o=${orderParam}&s=${shipment_id}&t=${order_type}&auth=${auth}&cb=${encodeURIComponent(window.location.href)}`);
                }
            }

            if (!!order.cancel) {
                if (domain == "localhost") {
                    setCancelURL(`${protocol}://${domain}:${port}/cancelOrder?o=${orderParam}&s=${shipment_id}&t=${order_type}&auth=${auth}&cb=${encodeURIComponent(window.location.href)}`);
                } else {
                    setCancelURL(`${protocol}://${domain}/cancelOrder?o=${orderParam}&s=${shipment_id}&t=${order_type}&auth=${auth}&cb=${encodeURIComponent(window.location.href)}`);
                }
            }

            if (!!order.extend_restock) {
                const restock_extend_days = order.restock_extend_days;
                if (domain == "localhost") {
                    setExtendRestockURL(`${protocol}://${domain}:${port}/extendRestock?&o=${orderParam}&s=${shipment_id}&t=${order_type}&auth=${auth}&restock_extend_days=${restock_extend_days}&cb=${encodeURIComponent(window.location.href)}`);
                } else {
                    setExtendRestockURL(`${protocol}://${domain}/extendRestock?&o=${orderParam}&s=${shipment_id}&t=${order_type}&auth=${auth}&restock_extend_days=${restock_extend_days}&cb=${encodeURIComponent(window.location.href)}`);
                }
            }

            if (order.order_content == "HB") {
                if ((hgData.hgOMW || balloonData.balloonOMW) && store_status !== "closed") {
                    setReadyOMW(true);
                }
            } else {
                if (((order.order_content == "H" && hgData.hgOMW) || (order.order_content == "B" && balloonData.balloonOMW)) && store_status !== "closed") {
                    setReadyOMW(true);
                }
            }

            let formatPickupTime = false;

            console.log("Balloon Data", balloonData)
            console.log("HG Data", hgData)

            if (order.order_content == "HB" && balloonData.balloonPickUpTime !== false) {
                if (hgData.hgPickedUp) {
                    if (moment().format("YYYY-MM-DD") == moment(balloonData.balloonPickUpTime).format("YYYY-MM-DD")) {
                        formatPickupTime = "Today @ " + moment(balloonData.balloonPickUpTime).format("h:mma");
                    } else {
                        formatPickupTime = moment(balloonData.balloonPickUpTime).format("MMM D h:mma");
                    }
                } else {
                    if (moment().format("YYYY-MM-DD") == moment(balloonData.balloonPickUpTime).format("YYYY-MM-DD")) {
                        formatPickupTime = "Today @ " + moment(balloonData.balloonPickUpTime).format("h:mma");
                    } else if (moment(balloonData.balloonPickUpTime).isBefore(moment())) {
                        formatPickupTime = "Now until " + moment(hgData.hgPickUpTime).format("MMM D");
                    } else {
                        formatPickupTime = "Now until " + moment(balloonData.balloonPickUpTime).format("MMM D");
                    }
                }
            } else {
                if (balloonData.balloon) {
                    if (moment().format("YYYY-MM-DD") == moment(balloonData.balloonPickUpTime).format("YYYY-MM-DD")) {
                        formatPickupTime = "Today @ " + moment(balloonData.balloonPickUpTime).format("h:mma");
                    } else {
                        formatPickupTime = moment(balloonData.balloonPickUpTime).format("MMM D h:mma");
                    }
                } else {
                    formatPickupTime = "By " + moment(hgData.hgPickUpTime).format("MMM D");
                }
            }

            setFormattedPickupTime(formatPickupTime)

            console.log('pickupTimeHG#####', pickupTimeHG, 'balloonPickedUp', balloonPickedUp)
            if (order.order_content == "HB" && order.shipment.length == 2) {

                /**
                 * NOTE: this code block is for checking if the HG side is picked up in a mixed order and if the balloon side exists, if so redirect link to balloon side
                 *       this will be a better user experience so the end-user won't have to click a specific shipment link to proceed with the flow
                 */
                const shipment_ids = ls.get(`shipment_ids_${order_id}`);
                if (shipment_ids.length > 1) {
                    console.log('balloonPickedUp@@@', hgPickedUp, pickupTimeHG)
                    if ((hgData.hgPickedUp || hgData.hgCancelled) && redirect !== "true") {
                        for (let key in shipment_ids) {
                            let shipment = ls.get(`shipment_${shipment_ids[key]}`)
                            if (shipment.order_type.indexOf("BALLOON") !== -1) {
                                let balloon_shipment_id = shipment.shipment_id;
                                if (shipment_id !== balloon_shipment_id) {
                                    window.location = `?o=${initials}${order_id}&s=${balloon_shipment_id}&t=${order_type}&auth=${shipment.auth}&redirect=true`
                                }
                            }
                            console.log('shipment@@', shipment)
                        }

                    }

                    if (!balloonData.balloonReady && !hgData.hgPickedUp && redirect !== "true") {
                        for (let key in shipment_ids) {
                            let shipment = ls.get(`shipment_${shipment_ids[key]}`)
                            if (shipment.order_type.indexOf("BALLOON") == -1) {
                                let hg_shipment_id = shipment.shipment_id;
                                if (shipment_id !== hg_shipment_id) {
                                    window.location = `?o=${initials}${order_id}&s=${hg_shipment_id}&t=${order_type}&auth=${shipment.auth}&redirect=true`
                                }
                            }
                            console.log('shipment@@', shipment)
                        }
                    }

                    if (!balloonData.balloonPickedUp || !hgData.hgPickedUp) {
                        const date = hgData.hgPickUpTime;
                        const moment_scheduled_dttm = moment(date).add(1, "days").set({
                            "hour": 0,
                            "minute": 0,
                            "second": 0
                        });
                        const now = moment();
                        if (now.isAfter(moment_scheduled_dttm)) {
                            setIsCancelled(true)
                            setIsExpired(false)
                            setIsMixedOrder(true)
                        }
                    }

                }

                if (hgData.hgCancelled && balloonData.balloonCancelled) {
                    let error_message = "at Party City " + store_cache.store_name + " has been Canceled";
                    setIsExpired(true)
                    setErrorMessage(error_message)
                }

                if ((hgData.hgCancelled && balloonData.balloonPickedUp) || (hgData.hgPickedUp && balloonData.balloonCancelled)) {
                    setGenericError(false)
                    setErrorMessage(false)
                    setIsCancelled(false)
                    setIsExpired(true)
                }

                if ((balloonData.balloonPickedUp && hgData.hgPickedUp) && (!hgData.hgCancelled && !balloonData.balloonCancelled)) {
                    setIsExpired(true)
                    if (!balloonData.balloonOMSPickedUp || !hgData.hgOMSPickedUp) {
                        setErrorMessage(<span>Thank you for your patience!<br/>If you have not received your order<br/><b>{order.order_reference_id}</b><br/>we will be with you shortly</span>)
                        setBarCode(true)
                        setGenericError(true)
                    }
                } else {
                    let ready = false;

                    if (((hgData.hgPickedUp || hgData.hgCancelled) && balloonData.balloonReady) &&  (store_status === "open" || store_status == "closing soon")) {
                        ready = true;
                    }
                    if ((!hgData.hgPickedUp && !hgData.hgCancelled) && (store_status === "open" || store_status == "closing soon")) {
                        ready = true;
                    }
                    setReadyToProceed(ready)
                }
            } else {
                let shipment = ls.get(`shipment_${shipment_id}`)
                console.log("shipment", shipment);
                if (shipment.status === "Picked Up") {
                    setIsExpired(true)
                    if (shipment.order_type.indexOf("BALLOON") !== -1) {
                        setErrorMessage("at Party City " + store_cache.store_name + " has already been picked up or it is past your pickup date and your order is no longer available")
                    }
                    if (!shipment.oms_picked_up) {
                        setErrorMessage(<span>Thank you for your patience!<br/>If you have not received your order<br/><b>{order.order_reference_id}</b><br/>we will be with you shortly</span>)
                        setBarCode(true)
                        setGenericError(true)
                    }
                } else if (shipment.status == "cancelled") {
                    let error_message = "at Party City " + store_cache.store_name + " has been Canceled";
                    setIsExpired(true)
                    setErrorMessage(error_message)
                } else {
                    let too_late = false;
                    let date = false;
                    let error_message = false;
                    if (shipment.order_type.indexOf("BALLOON") !== -1) {
                        error_message = "at Party City " + store_cache.store_name + " has already been picked up or it is past your pickup date and your order is no longer available";
                        date = balloonData.balloonPickUpTime;
                        const moment_scheduled_dttm = moment(date).add(1, "days").set({
                            "hour": 0,
                            "minute": 0,
                            "second": 0
                        });
                        const now = moment();
                        if (now.isAfter(moment_scheduled_dttm)) {
                            too_late = true;
                        }
                    } else {
                        error_message = "at Party City " + store_cache.store_name + " has been Canceled and will be refunded";
                        date = hgData.hgPickUpTime;
                        console.log("exception");
                        const moment_scheduled_dttm = moment(date).add(1, "days").set({
                            "hour": 0,
                            "minute": 0,
                            "second": 0
                        });
                        const now = moment();
                        if (now.isAfter(moment_scheduled_dttm)) {
                            too_late = true;
                        }
                    }
                    if (too_late) {
                        setIsExpired(true)
                        setErrorMessage(error_message)
                    } else {
                        if (store_status == "open" || store_status == "closing soon") {
                            setReadyToProceed(true)
                        }
                    }

                }
            }

            /**
             * order not ready logic
             */

            if (order.order_content == "HB") {
                if (hgData.hgPickedUp && (!balloonData.balloonPickComplete || !balloonData.balloonOMW)) {
                    setModalOrderNotReadyVerbiage("Balloon");
                    setIsOpenOrderNotReady(true);
                } else {
                    if (!hgData.hgPickComplete && !balloonData.balloonOMW) {
                        setModalOrderNotReadyVerbiage("HG");
                        setIsOpenOrderNotReady(true);
                    }
                }
            } else {
                if (hgData.hg) {
                    if (!hgData.hgPickComplete) {
                        setModalOrderNotReadyVerbiage("HG");
                        setIsOpenOrderNotReady(true);
                        setReadyToProceed(false)
                    }
                } else {
                    if (!balloonData.balloonPickComplete || !balloonData.balloonOMW) {
                        setModalOrderNotReadyVerbiage("Balloon");
                        setIsOpenOrderNotReady(true);
                        setReadyToProceed(false)
                    }
                    if (!balloonData.balloonPickComplete || !balloonData.balloonReady) {
                        setReadyToProceed(false)
                    }
                }
            }

        }

        ls.flush();
    },[])

    const getOffset = id => {
        const el = document.getElementById(id);
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY
        };
    }

    const handleOnIdle = async event => {
        console.log('user is idle', event)
        const time = checkOpeningTime();

        console.log(time);

        if (time == parseInt(process.env.REACT_APP_IDLE_TIMEOUT_INTERVAL_MS) && !earlyCall) {
            if(process.env.REACT_APP_IDLE_TIMEOUT == "true" && firstTimeout) {
                openModalRefresh()
            } else {
                setIdleTime(time);
                setFirstTimeout(true);
                setTimeout(function() {
                    idleTimer.reset();
                }, 2000);
            }
        } else {
            setEarlyCall(true);
            if (time > 0) {
                setIdleTime(3000);
                idleTimer.reset();
            } else {
                if(process.env.REACT_APP_IDLE_TIMEOUT == "true") {
                    openModalRefresh()
                }
            }
        }
    }

    const checkOpeningTime = () => {
        const today = store.expanded_hours[0][moment().format("YYYY-MM-DD")];

        if (today.status == "closed") {
            return parseInt(process.env.REACT_APP_IDLE_TIMEOUT_INTERVAL_MS);
        } else {
            return (earlyCall) ?
                getMSOpenDiff() :
                moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + today.open).subtract(0, "minutes")) ?
                    parseInt(process.env.REACT_APP_IDLE_TIMEOUT_INTERVAL_MS) :
                    moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + today.open).subtract(30, "minutes")) ?
                        getMSOpenDiff() :
                        parseInt(process.env.REACT_APP_IDLE_TIMEOUT_INTERVAL_MS);
        }
    }

    const getMSOpenDiff = () => {
        const today = store.expanded_hours[0][moment().format("YYYY-MM-DD")];
        const open_dt = moment(moment().format("YYYY-MM-DD") + " " + today.open);
        const now_dt = moment();
        return open_dt.diff(now_dt);
    }

    const openModal = () => {
        setIsOpen(true);
        applyModalStyle();
    }

    const openOOSModal = () => {
        setOOSIsOpen(true);
        applyModalStyleOOS();
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const closeModalOOS = () => {
        setOOSIsOpen(false);
    }


    const closeModalReschedule = () => {
        setIsOpenReschedule(false);
    }

    const openModalOMW = () => {
        setIsOpenOMW(true);
        applyModalStyleOMW();
    }

    const closeModalOMW = () => {
        setIsOpenOMW(false);
    }

    const openModalRefresh = () => {
        setIsOpenRefresh(true);
        applyModalStyleRefresh();
    }
    const openModalReschedule = () => {
        setIsOpenReschedule(true);
        applyModalStyleReschedule();
    }
    const closeModalRefresh = () => {
        setIsOpenRefresh(false);
    }

    const setModalOrderNotReadyVerbiage = type => {
        let verbiage = "";

        switch (type) {
            case "HG":
                verbiage = "Your order is not ready for pickup yet. Please check back shortly.";
                break;
            case "Balloon":
                verbiage = "Your order is not ready for pickup yet. Please check back at your scheduled pickup time.";
                break;
        }

        setorderNotReadyVerbiage(verbiage)
        applyModalStyleOMW();
        setReadyToProceed(false);
    }

    const closeModalOrderNotReady = () => {
        setIsOpenOrderNotReady(false);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    const applyModalStyle = () => {
        let top = 0;
        let left = 0;

        /**
         * This is for positioning the arrow for the tooltip to match the info bubble for pickup time disclaimer
         */
        const info_bubble_id = "pickup_time_info_bubble";
        const offset = getOffset(info_bubble_id);
        var style = document.createElement('style');
        style.innerHTML = '.ReactModal__Content--after-open {   inset: 0px !important; top: ' + ( offset.top + 30) + 'px !important; width: 350px !important; height:170px !important; padding: 10px !important; overflow:hidden !important;  margin-left: auto !important; margin-right: auto !important;} .ReactModal__Overlay--after-open {\n' +
            '    background-color: transparent !important;\n' +
            '}';
        document.head.appendChild(style);
    }

    const applyModalStyleReschedule = () => {
        let top = 0;
        let left = 0;

        /**
         * This is for positioning the arrow for the tooltip to match the info bubble for pickup time disclaimer
         */
        const info_bubble_id = "pickup_time_info_bubble";
        const offset = getOffset(info_bubble_id);
        var style = document.createElement('style');
        style.innerHTML = '.ReactModal__Content--after-open {   inset: 0px !important; top: ' + ( offset.top + 30) + 'px !important; width: 350px !important; height:250px !important; padding: 10px !important; overflow:hidden !important;  margin-left: auto !important; margin-right: auto !important;} .ReactModal__Overlay--after-open {\n' +
            '}';
        document.head.appendChild(style);
    }

    const applyModalStyleOOS = () => {
        let top = 0;
        let left = 0;

        /**
         * This is for positioning the arrow for the tooltip to match the info bubble for pickup time disclaimer
         */
        const info_bubble_id = "pickup_time_info_bubble";
        const offset = getOffset(info_bubble_id);
        var style = document.createElement('style');
        style.innerHTML = '.ReactModal__Content--after-open {   inset: 0px !important; top: ' + ( offset.top + 30) + 'px !important; width: 350px !important; height:fit-content !important; padding: 10px !important; overflow:hidden !important;  margin-left: auto !important; margin-right: auto !important;} .ReactModal__Overlay--after-open {\n' +
            '    background-color: transparent !important;\n' +
            '}';
        document.head.appendChild(style);
    }

    const applyModalStyleRefresh = () => {
        let top = 0;
        let left = 0;

        /**
         * This is for positioning the arrow for the tooltip to match the info bubble for pickup time disclaimer
         */
        const info_bubble_id = "pickup_time_info_bubble";
        const offset = getOffset(info_bubble_id);
        var style = document.createElement('style');
        style.innerHTML = '.ReactModal__Content--after-open {   inset: 0px !important; top: ' + ( offset.top + 30) + 'px !important; width: 350px !important; height:170px !important; padding: 10px !important; overflow:hidden !important;  margin-left: auto !important; margin-right: auto !important;}    .ReactModal__Overlay--after-open { background-color: rgba(0, 0, 0, 0.75) !important;  }';
        document.head.appendChild(style);
    }

    const applyModalStyleOMW = () => {
        let top = 0;
        let left = 0;

        /**
         * This is for positioning the arrow for the tooltip to match the info bubble for pickup time disclaimer
         */
        const info_bubble_id = "pickup_time_info_bubble";
        const offset = getOffset(info_bubble_id);
        var style = document.createElement('style');
        style.innerHTML = '.ReactModal__Content--after-open {   inset: 0px !important; top: ' + ( offset.top + 30) + 'px !important; width: 350px !important; height:fit-content !important; padding: 10px !important; overflow:hidden !important;  margin-left: auto !important; margin-right: auto !important;}    .ReactModal__Overlay--after-open { background-color: rgba(0, 0, 0, 0.75) !important;  }';
        document.head.appendChild(style);
    }


    return (

        <>
            {(apiError || isExpired || isCancelled || !search) &&
            <ErrorPage isExpired={isExpired} order_id={orders.order_reference_id} store_name={store.store_name} pickedup_time={shipments.picked_up_dttm} isCancelled={isCancelled} isMixedOrder={isMixedOrder} errorMessage={errorMessage} genericError={genericError} barCodeFlag={barCode}/>
            }


            {(orders && Object.keys(orders).length>0 && !apiError && !isExpired && !isCancelled && search) &&

            <LoadingOverlay
                active={loading}
                spinner
                text='Loading your content...'
            >
                <Layout align="left">

                    {(apiError || isExpired || isCancelled) &&
                    <ErrorPage isExpired={isExpired} order_id={orders.order_reference_id} store_name={store.store_name} pickedup_time={shipments.picked_up_dttm} isCancelled={isCancelled} isMixedOrder={isMixedOrder} errorMessage={errorMessage} genericError={genericError} barCodeFlag={barCode}/>
                    }

                    {(orders && Object.keys(orders).length>0 && !apiError && !isExpired && !isCancelled) &&
                    <>


                        <IdleTimer
                            ref={ref => { setIdleTimer(ref) }}
                            element={document}
                            onIdle={handleOnIdle}
                            timeout={idleTime}
                        />



                        <PageHeader
                            headerText="In-Store or Curbside Pickup"
                        />
                        <br style={{clear:"both"}}/>
                        <br style={{clear:"both"}}/>
                        <br style={{clear:"both"}}/>
                        <br style={{clear:"both"}}/>


                        <div className="container" style={{padding:"0px"}}>
                            <div className="row">
                                <div className="col-9" style={{paddingRight:"0px"}}>
                                    {
                                        (typeof orders.order_content !== "undefined") &&
                                        <>
                                        {
                                            (orders.order_content !== "H") &&
                                            <>

                                                {
                                                    (!!rescheduleURL) &&
                                                    <>
                                                        <div style={{textAlign:"left"}}>
                                                            <a href={rescheduleURL} style={{color:"red",fontWeight:"bolder",fontSize:"12pt",textDecoration:"underline",fontFamily:"futuraptbold"}}>Reschedule Order</a>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    (!rescheduleURL) &&
                                                    <>
                                                        <div style={{textAlign:"left"}}>
                                                            <a href="#" onClick={openModalReschedule} style={{color:"red",fontWeight:"bolder",fontSize:"12pt",textDecoration:"underline",fontFamily:"futuraptbold"}}>Reschedule Order</a>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }

                                        </>
                                    }
                                    {
                                        (!!extendRestockURL) &&
                                        <>
                                            <div style={{textAlign:"left"}}>
                                                <a href={extendRestockURL} style={{color:"red",fontWeight:"bolder",fontSize:"12pt",textDecoration:"underline",fontFamily:"futuraptbold"}}>Extend Pickup Date</a>
                                            </div>
                                        </>
                                    }
                                    <div><b className="order-details-header">Order:</b> <b className="orderDetailsValue"> {orders.order_reference_id}</b></div>
                                    <div><b className="order-details-header">Pickup:</b> <b className="orderDetailsValue" style={{marginLeft:"2px"}}>  {formattedPickupTime}</b>
                                        <span style={{textDecoration:"none"}}>&nbsp;<img onClick={openModal} id="pickup_time_info_bubble" src={information_bubble}  style={{width: "18px" , height: "18px", marginTop: "-5px"}} /></span>
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                        >
                                    <span style={{fontSize:"12px",fontWeight:"bold"}} >
                                            <b>*Order Pickup Deadline:</b><br/>
                                                <ol style={{paddingInlineStart:"15px", marginTop:"10px", marginBottom: "20px"}}>
                                                    <li style={{fontWeight:"bolder"}}>Orders must be picked up by your Pickup Date.</li>
                                                    <li style={{fontWeight:"bolder",marginTop:"10px"}}>For unclaimed orders after the Pickup Date,<br/>inflated balloons will be discarded (non-refundable). All other merchandise will be canceled, returned to shelves and a refund issued.</li>
                                                </ol>
                                        </span>
                                        </Modal>
                                        <Modal
                                            isOpen={modalIsOpenOOS}
                                            onRequestClose={closeModalOOS}
                                        >
                                    <span style={{fontSize:"12px",fontWeight:"bold"}} >
                                            <b style={{color:"red"}}>*Unfortunately, some of the item(s) are out of stock, and we've had to remove them from your order. Please review your out of stock email for order details</b>
                                        </span>
                                        </Modal>
                                    </div>
                                    <p style={{lineHeight: "1px",marginBottom:"10px"}}></p>
                                    <div style={{position: "relative"}}>
                                        <Button className="iamonmyway" data-visible={(!onMyWay) ? "true" : "false"} data-active={(readyOMW && !onMyWay) ? "true" : "false"} onClick={handleOnMyway}>I'm On My Way</Button>
                                    </div>

                                    {
                                        typeof customer.secondary_contact !== "undefined" &&
                                        <>
                                            {
                                                (customer.secondary_contact.first_name !== "" && customer.secondary_contact.last_name !== "") &&
                                                <>
                                                    <div style={{marginTop:"10px",fontWeight:"500"}}><img src={second_contact} style={{height:"25px",width:"25px"}} /> {customer.secondary_contact.first_name} {customer.secondary_contact.last_name}</div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                                <div className="col-3" style={{paddingLeft:"0px"}}>
                                    <div ><Image  src={(shipments.order_type == 'BOPIS' ||  shipments.order_type == 'BALLOON-PICKUP')?party_pickup:party_pickup} style={{width: "110px" , height: "110px",marginLeft:"-20px"}}/></div>
                                </div>
                            </div>
                        </div>


                        <br/>
                        {
                            (typeof store.expanded_hours !== "undefined") &&
                            <>
                                <StoreInformation
                                    store={store}
                                />
                            </>
                        }
                        <br/>

                        {
                            (!!cancelURL) &&
                            <>
                                <Row>
                                    <div style={{textAlign:"left"}}>
                                        <a href={cancelURL} style={{color:"red",fontWeight:"bold",textDecoration:"underline",fontFamily:"futuraptbold", fontSize:"12pt"}}>Cancel Order</a>
                                    </div>
                                </Row>
                            </>
                        }

                        <Row>
                            <div className="col-7 order-status"><img src={order_status} style={{width:"15px",height:"15px", marginTop:"-3px"}} />&nbsp;&nbsp;Your Order Status</div><div className="col-5" style={{padding:"0px",marginTop:"-4px"}}>{(balloonOOS || hgOOS) ? <span><img id="oos_info_bubble" onClick={openOOSModal} src={oos_information_bubble} style={{marginTop:"-4px",display:"none"}}/>&nbsp;&nbsp;<span style={{color:"red",textDecoration:"underline",fontWeight:"700",display:"none"}} onClick={openOOSModal}>Order Update</span></span> : <span></span> }</div>
                        </Row>

                        <br/>

                        <div id="order-status-container">

                            <div className="row">
                                {
                                    hg &&
                                    <>
                                        <div className={(orders.order_content == "HB" && orders.shipment.length == 2) ? "col-6" : "col-12"}>

                                            <div className="shipment-status" data-half={(orders.order_content == "HB" && orders.shipment.length == 2) ? "true" : "false"} data-show={(hg) ? "true" : "false"} data-active={(hgReady && !hgPickedUp && !hgCancelled) ? "true" : "false"}>
                                                <div className="shipment-status-header"  data-active={(hgReady && !hgPickedUp && !hgCancelled) ? "true" : "false"}>
                                                    Merchandise
                                                </div>
                                                <div className="shipment-status-image">
                                                    <img src={(hgCancelled || !hgReady) ? party_pickup_cancelled : (!hgPickedUp) ? party_pickup_green : party_pickup_grey} style={(hgCancelled || !hgReady) ? {width:"44px",height:"72px"} : {width:"72px",height:"72px"}} />
                                                </div>
                                                <div className="shipment-status-description"  data-active={(hgReady && !hgPickedUp && !hgCancelled) ? "true" : "false"}>
                                                    {(hgCancelled && hgOOS) ?
                                                        <span>Out of Stock</span> :
                                                        (hgCancelled) ?
                                                            <span>Cancelled</span> :
                                                            (!hgPickComplete) ?
                                                                <span>Available Soon</span> :
                                                                (hgPickedUp) ?
                                                                    <span>Picked up<br/>on {moment(pickedUpTimeHG).format("M/D/YY")}</span> :
                                                                    (orders.order_content == "HB" && moment().isAfter(moment(pickupTimeBalloon).subtract(1, "days").set({"hour": 23, "minute": 59, "second": 59}))) ?
                                                                        <span>Ready! Pickup<br/>Today @{moment(pickupTimeBalloon).format("h:mma")}</span> :
                                                                        <span>Ready! Pickup<br/>by {
                                                                            moment(pickupTimeHG).format("M/D/YY")}</span>}
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                }

                                {
                                    balloon &&
                                    <>
                                        <div className={(orders.order_content == "HB" && orders.shipment.length == 2)  ? "col-6" : "col-12"}>
                                            <div className="shipment-status" data-half={(orders.order_content == "HB" && orders.shipment.length == 2) ? "true" : "false"} data-show={(balloon) ? "true" : "false"}  data-active={(balloonReady && !balloonCancelled) ? "true" : "false"}>
                                                <div className="shipment-status-header"  data-active={(balloonReady && !balloonCancelled) ? "true" : "false"}>
                                                    Inflated Balloons
                                                </div>
                                                <div className="shipment-status-image">
                                                    <img src={(moment().isBefore(moment(pickupTimeBalloon)) || balloonCancelled || !balloonPickComplete) ? balloons_grey : balloons_green } style={{width:"72px",height:"72px"}} />
                                                </div>
                                                <div className="shipment-status-description"  data-active={(balloonReady) ? "true" : "false"}>
                                                    {
                                                        balloonReady == false &&
                                                        <>
                                                            {
                                                                (balloonCancelled && balloonOOS) &&
                                                                <>
                                                                    Out of stock
                                                                </>
                                                            }
                                                            {
                                                                (balloonCancelled && !balloonOOS) &&
                                                                <>
                                                                    Cancelled
                                                                </>
                                                            }
                                                            {
                                                                balloonPickedUp == true &&
                                                                <>
                                                                    <span>Picked Up</span>
                                                                </>
                                                            }
                                                            {
                                                                (!balloonCancelled && !balloonPickedUp) &&
                                                                <>
                                                                    Available {moment(pickupTimeBalloon).format("M/D/YY")}<br/>@{moment(pickupTimeBalloon).format("h:mma")}
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        balloonReady == true &&
                                                        <>
                                                            {
                                                                balloonPickedUp == true &&
                                                                <>
                                                                    <span>Picked Up</span>
                                                                </>
                                                            }
                                                            {
                                                                balloonPickedUp == false &&
                                                                <>
                                                                    {
                                                                        moment().isAfter(moment(pickupTimeBalloon).add(1, "days").set({"hour": 0, "minute": 0, "second": 0})) &&
                                                                        <>
                                                                            <span>Ready for<br/>pickup</span>
                                                                        </>
                                                                    }
                                                                    {
                                                                        moment().isBefore(moment(pickupTimeBalloon).add(1, "days").set({"hour": 0, "minute": 0, "second": 0})) &&
                                                                        <>
                                                                            <span>Ready! Pickup<br/>Today @{moment(pickupTimeBalloon).format("h:mma")}</span>
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }




                            </div>

                        </div>



                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        <Modal
                            isOpen={modalIsOpenOMW}
                            onRequestClose={closeModalOMW}
                        >
                            <p style={{textAlign:"right"}}  className="modal-close" onClick={closeModalOMW}>X</p>
                            <p style={{textAlign:"center",fontSize:"16px", padding:"10px"}}>

                                {
                                    typeof store.expanded_hours !== "undefined" &&
                                    <>
                                    {
                                        typeof store.expanded_hours[0][moment().format("YYYY-MM-DD")].open !== "undefined" &&
                                        <>
                                        {
                                            moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + store.expanded_hours[0][moment().format("YYYY-MM-DD")].open).subtract(0, "minutes")) ?
                                                <b>Thanks, we'll start preparing your order! Please be sure to let us know when you've arrived.</b> :
                                                moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + store.expanded_hours[0][moment().format("YYYY-MM-DD")].open).subtract(30, "minutes")) ?
                                                    <b>Thanks, we'll start preparing your order!<br/><br/><font style={{color:"red"}}>Once the store has opened please let us know you’ve arrived.</font></b> :
                                                    <b>Thanks, we'll start preparing your order! Please be sure to let us know when you've arrived.</b>
                                        }
                                        </>
                                    }
                                    {
                                        typeof store.expanded_hours[0][moment().format("YYYY-MM-DD")].open == "undefined" &&
                                        <>
                                        <b>Thanks, we'll start preparing your order! Please be sure to let us know when you've arrived.</b>
                                        </>
                                    }
                                    </>
                                }

                                <br/>
                                <br/>
                                <button className="closeOMWModal" onClick={closeModalOMW}>Close</button>
                            </p>
                        </Modal>


                        <Modal
                            isOpen={modalIsOpenOrderNotReady}
                            onRequestClose={closeModalOrderNotReady}
                        >
                            <p style={{textAlign:"right"}} className="modal-close" onClick={closeModalOrderNotReady}>X</p>
                            <p style={{textAlign:"center",fontSize:"16px", padding:"10px"}}>
                                <b>Your order is not ready for pickup yet! We’ll notify you when it’s ready.</b>
                                <br/>
                                <br/>
                                <button className="closeOMWModal" onClick={closeModalOrderNotReady}>Close</button>
                                <br/>
                                <br/>
                                <span style={{color:"gray"}}>Please note it may take a few minutes for your order status to update.</span>
                            </p>
                        </Modal>

                        <Modal
                            isOpen={modalIsOpenReschedule}
                            onRequestClose={closeModalReschedule}
                        >
                            <p style={{textAlign:"right"}} className="modal-close" onClick={closeModalReschedule}>X</p>
                            <p style={{textAlign:"center",fontSize:"16px", padding:"10px"}}>
                                <b>Oops, Your order cannot be rescheduled at this time. Your local Party City store can best help you answer any questions you may have.</b>
                                <br/>
                                <br/>
                                <button className="closeOMWModal" onClick={closeModalReschedule}>Close</button>
                            </p>
                        </Modal>

                        <Modal
                            isOpen={modalIsOpenRefresh}
                            onRequestClose={closeModalRefresh}
                        >
                            <p style={{textAlign:"center",fontSize:"16px", padding:"10px"}}>
                                <b>That page has expired please click refresh to see current order status</b>
                                <br/>
                                <br/>
                                <button className="closeOMWModal" onClick={refreshPage}>Refresh</button>
                            </p>
                        </Modal>


                        <Card className="page-footer" style={{height:"120px"}}>
                            <Row style={{padding:"0px"}}>
                                <div id="footerCopy" className="tellusText">{(onMyWay) ? <span>Let us know when you arrive<br/>and your pickup method</span> : <span>Already at the store?<br/>Let us know your pickup method.</span>}</div>
                            </Row>
                            <p style={{lineHeight: "1px",marginBottom:"30px"}}></p>
                            {
                                typeof store.store_services !== "undefined" &&
                                <>
                                    <div className="container-fluid">
                                        <Row>
                                            <div className="col-6">
                                                <Button className="iamhere" data-method="IN-STORE" data-enabled={(store.store_services.indexOf("IS") !== -1) ? "true": "false"} data-active={readyToProceed ? "true" : "false"} onClick={handleIamHere}>{(store.store_services.indexOf("IS") !== -1) ? <span data-method="IN-STORE" data-enabled={(store.store_services.indexOf("IS") !== -1) ? "true": "false"} data-active={readyToProceed ? "true" : "false"}>I've arrived for<br/>In-Store Pickup</span> : <span data-method="IN-STORE" data-enabled={(store.store_services.indexOf("IS") !== -1) ? "true": "false"} data-active={readyToProceed ? "true" : "false"}>In-Store Pickup<br/>Unavailable</span>}</Button>
                                            </div>
                                            <div className="col-6">
                                                <Button className="iamhere" data-method="CURBSIDE" data-enabled={(store.store_services.indexOf("CS") !== -1 && !curbsideCutoff) ? "true": "false"} data-active={(readyToProceed && !curbsideCutoff) ? "true" : "false"} onClick={handleIamHere}>{(store.curbside_na == "true") ?  <span data-method="CURBSIDE" data-enabled="false" data-active="false">Curbside Pickup<br/>Not Available</span> : (store.store_services.indexOf("CS") !== -1 && !curbsideCutoff) ? <span data-method="CURBSIDE" data-enabled={(store.store_services.indexOf("CS") !== -1) ? "true": "false"} data-active={readyToProceed ? "true" : "false"}>I've arrived for<br/>Curbside Pickup</span> : <span data-method="CURBSIDE" data-enabled={(store.store_services.indexOf("CS") !== -1) ? "true": "false"} data-active={readyToProceed ? "true" : "false"}>Curbside Pickup<br/>Unavailable</span>}</Button>
                                            </div>
                                        </Row>
                                    </div>

                                </>
                            }
                        </Card>
                        <UTag type="load"
                              page_name={"microsite"}
                              customer={ls.get(`customer_${order_id}`)}
                              orders={ls.get(`orders_${order_id}`)}
                              shipments={ls.get(`shipment_${shipment_id}`)}
                              store={ls.get(`store_${order_id}`)}
                              customer_id={ls.get(`CustomerKey_${(!ls.get(`customer_${order_id}`) ? "" : ls.get(`customer_${order_id}`).email_address)}`)}
                              vehicle_info={{"vehicle_type": "", "vehicle_color": "", "vehicle_make": "", "bag_location": ""}}
                        />
                    </>
                    }

                </Layout>

            </LoadingOverlay>
            }
        </>
    );
};

export default Microsite;