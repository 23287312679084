import {Button, Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import moment from 'moment';
import {post} from "axios";

let mainInterval;


const RescheduleDeliveryTimer = (props) => {

    let timer;
    let end;
    const [formattedTime, setFormattedTime] = useState("02:00");
    const [pauseCounter, setPauseCounter] = useState(0);
    const [resetCounter, setResetCounter] = useState(0);

    const reset = () => {
        pause();
        setFormattedTime("02:00");
        end = moment().add(121, "seconds");
        timer = moment();
        run();
    }

    const pause = () => {
        clearInterval(mainInterval);
    }

    const run = () => {
        mainInterval = setInterval(intervalLogic.bind(this), 1000);
    }

    const intervalLogic = () => {
        timer.add(1, "seconds");
        getFormattedTime();
    }

    const getFormattedTime = () => {
        const ms_diff = end.diff(timer);
        const seconds = ms_diff / 1000;
        const seconds_display = Math.floor(seconds % 60);
        const minutes = Math.floor(seconds / 60);
        const formatted_time = String(minutes).padStart(2, '0') + ":" + String(seconds_display).padStart(2, '0');
        if (formatted_time == '00:00') {
            back();
        }
        setFormattedTime(formatted_time);
    }

    const back = async () => {
        if (props.modalOpen) {
            pause();
            await sendEmailAlert();
        }
        window.location.href = decodeURIComponent(props.cb);
    }

    const sendEmailAlert = async() => {
        const body={"action":"send_delivery_email_alert","o":props.orderParam,"t": "D", "application_id": props.application_id,"user_name": props.user_name,"user_role": props.user_role, "current_reschedule_status": props.currentRescheduleStatus}
        const headers = {
            'x-api-key': props.api_key
        };
        try {
            const res = await post(`${props.email_url}/send_delivery_email_alert`, body, {headers})
        } catch (e) {

        }
        return;
    }

    const process = () => {
        if (props.reset > resetCounter) {
            setResetCounter(props.reset);
            reset();
        }
        if (props.pause > pauseCounter) {
            setPauseCounter(props.pause);
            pause();
        }
    }

    useEffect(async ()=> {
        process();
    },[props.reset, props.pause])


    return (
        <Card className="reschedule-delivery-timer">
            <Row>
                <Col className="col-sm-12">
                    <b style={{color:"red"}}>{formattedTime}</b>
                </Col>
            </Row>
        </Card>
    );
};

export default RescheduleDeliveryTimer;