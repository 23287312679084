import React, {useEffect, useState} from "react";


const UTag = (props) => {
    const analytics = () => {
        console.log(props)

        if(!props.type) {
            return;
        }

        let data = {};

        switch (props.type) {
            case "load":
                data = {
                    "site_name": "omni_microsite",
                    "customer_email": (!props.customer.email_address) ?  "" : props.customer.email_address,
                    "customer_id": (!props.customer_id) ?  "" : props.customer_id,
                    "page_name": (!props.page_name) ?  "" : props.page_name,
                    "page_type": "Order Pickup",
                    "pickup_order_id": (!props.orders.order_reference_id) ?  "" : props.orders.order_reference_id,
                    "nav_level_1": "Order Pickup",
                    "store_id": (!props.store.store_id) ?  "" : props.store.store_id,
                    "pickup_method": (!props.shipments) ?  "" : (props.shipments.order_type.indexOf("CURBSIDE") !== -1) ? "Curbside" : "In Store",
                    "vehicle_type": (!props.vehicle_info.vehicle_type) ?  "" : props.vehicle_info.vehicle_type,
                    "vehicle_color": (!props.vehicle_info.vehicle_color) ?  "" : props.vehicle_info.vehicle_color,
                    "vehicle_make": (!props.vehicle_info.vehicle_make) ?  "" : props.vehicle_info.vehicle_make,
                    "bag_location": (!props.vehicle_info.bag_location) ?  "" : props.vehicle_info.bag_location
                }

                const vehicle_detals = (!props.vehicle_info.vehicle_type) ? false : true;

                if (!vehicle_detals) {
                    delete data.vehicle_type;
                    delete data.vehicle_color;
                    delete data.vehicle_make;
                    delete data.bag_location;
                }

                break;
        }

        if (typeof window.utag !== "undefined") {
            window.utag.view(data);
        } else {
            let env = "dev";

            if (process.env.REACT_APP_ENV == "prod") {
                env = "prod";
            }

            window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
            window.utag_cfg_ovrd.noview = true

            let aScript = document.createElement('script');
            aScript.type = 'text/javascript';
            aScript.src = "//tags.tiqcdn.com/utag/pcrg/pcus2.0/" + env + "/utag.js";
            aScript.async = true;

            document.head.appendChild(aScript);

            aScript.onload = function() {
                window.utag.view(data);
            }
        }
    }

    useEffect(async ()=> {
        analytics()
    },[props.type])

    return (
        <span style={{display:"none"}}>{props.type}</span>
    );
};



export default UTag;