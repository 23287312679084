import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import ls from "localstorage-slim";
import {setDeliveryLocalStorage} from './../util/util.js'
import {Row, Col, Card, Alert, Container,Image} from 'react-bootstrap'
import ErrorPage from "./ErrorPage";
import moment from 'moment';
import LoadingOverlay from "react-loading-overlay-ts";
import Layout from "./Layout";
import PageHeader from "./PageHeader";
import StoreInformation from "./StoreInformation";
import IdleTimer, { useIdleTimer } from 'react-idle-timer/modern'
import  header_icon from './../images/delivery_tracker/Delivery_Vehicle_Icon-01.svg'
import  progress_0 from './../images/delivery_tracker/FOD_Tracker_Connector-01.svg'
import  progress_1 from './../images/delivery_tracker/FOD_Tracker_Connector-02.svg'
import  progress_2 from './../images/delivery_tracker/FOD_Tracker_Connector-03.svg'
import  progress_3 from './../images/delivery_tracker/FOD_Tracker_Connector-04.svg'
import  shipt from './../images/delivery_tracker/carrier_logos/shipt.png'
import  uber from './../images/delivery_tracker/carrier_logos/uber.jpg'
import  doordash from './../images/delivery_tracker/carrier_logos/doordash.png'
import './../delivery_tracker.css'
import Modal from "react-modal";

const DeliveryTracker = () => {
    const navigate = useNavigate();
    let search = useLocation().search;
    let orderParam,order_id,initials,shipment_id,order_type,redirect,order_reference_id, auth
    const [orders, setOrders] = useState({})
    const [shipments, setShipments] = useState({})
    const [store, setStore] = useState({})
    const [customer, setCustomer] = useState({})
    const [rescheduleURL, setRescheduleURL] = useState(false)
    const [cancelURL, setCancelURL] = useState(false)
    const [pastWindow, setPastWindow] = useState(false)
    const [allDelivered, setAllDelivered] = useState(false)
    const [loading, setLoading] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [isExpired, setIsExpired] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [genericError, setGenericError] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false)
    const [firstTimeout, setFirstTimeout] = useState(false);
    const [idleTime, setIdleTime] = useState(3000);
    const [idleTimer, setIdleTimer] = useState(false);
    const [idleTimerFuse, setIdleTimerFuse] = useState(parseInt(process.env.REACT_APP_IDLE_TIMEOUT_FUSE));
    const [modalIsOpenReschedule, setIsOpenReschedule] = React.useState(false);
    const [modalIsOpenRefresh, setIsOpenRefresh] = React.useState(false);
    const [earlyCall, setEarlyCall] = React.useState(false);

    if(search){
        orderParam = new URLSearchParams(search).get('o')
        auth = new URLSearchParams(search).get('auth')
        order_id = orderParam.substring(2,orderParam.length);
        initials = orderParam.substring(0,2);
        order_type = new URLSearchParams(search).get('t');
        order_reference_id = order_id + "-D";
    }

    useEffect(async ()=> {
        let store_cache = false;
        let order_cache = false;

        if(search) {
            setLoading(true)

            let orders = ls.get(`orders_${order_id}`)
            let customer = ls.get(`customer_${order_id}`)
            let store = ls.get(`store_${order_id}`)
            store_cache = store;
            order_cache = orders;
            if (orders === null || shipments == null) {
                let res = await setDeliveryLocalStorage(search, order_id);
                if (res) {
                    if (res.error) {
                        let error_message = getErrorMessage(res.error)
                        setGenericError(error_message)
                        setApiError(true)
                    }
                    setOrders(res.order)
                    setStore(res.store)
                    store_cache = res.store;
                    order_cache = res.order;
                    setCustomer(res.customer)
                    setLoading(false)
                } else {
                    setGenericError(<span>Network Error</span>)
                    setLoading(false)
                    setApiError(true)
                }
            } else {
                setLoading(true)
                setOrders(orders)
                setCustomer(customer)
                setStore(store)
                setLoading(false)
            }
        }

        const domain = process.env.REACT_APP_DELIVERY_DOMAIN;
        const protocol = process.env.REACT_APP_PROTOCOL;
        const port = process.env.REACT_APP_PORT;

        if (!!order_cache) {
            if (!!order_cache.reschedule) {
                if (domain == "localhost") {
                    setRescheduleURL(`${protocol}://${domain}:${port}/rescheduleDelivery?ApplicationID=OMNI&UserName=Customer&UserRole=Customer&o=${orderParam}&t=D&auth=${auth}&cb=${encodeURIComponent(window.location.href)}`);
                } else {
                    setRescheduleURL(`${protocol}://${domain}/rescheduleDelivery?ApplicationID=OMNI&UserName=Customer&UserRole=Customer&o=${orderParam}&t=D&auth=${auth}&cb=${encodeURIComponent(window.location.href)}`);
                }
            }
            if (!!order_cache.cancel) {
                if (domain == "localhost") {
                    setCancelURL(`${protocol}://${domain}:${port}/cancelOrder?o=${orderParam}&t=D&auth=${auth}&cb=${encodeURIComponent(window.location.href)}`);
                } else {
                    setCancelURL(`${protocol}://${domain}/cancelOrder?o=${orderParam}&t=D&auth=${auth}&cb=${encodeURIComponent(window.location.href)}`);
                }
            }
            let all_delivered = true;
            for(let key in order_cache.driver_details) {
                if (order_cache.driver_details[key].progress !== 3) {
                    all_delivered = false;
                }
            }
            setAllDelivered(all_delivered);
        }

        if (!!store_cache) {
            const moment = require('moment-timezone');
            moment.tz.setDefault(store_cache.tz);
            let scheduled_dttm = moment(order_cache.scheduled_dttm);
            let now = moment();
            now.subtract(2, "hours");
            if (now.isAfter(scheduled_dttm)) {
                setPastWindow(true);
            }
        }


    },[])

    const getErrorMessage = error_code => {
        let message = <span>Network Error</span>
        switch (error_code.toString()) {
            case "1":
                message = <span>We know you are excited for your order {order_reference_id}, hold on tight as we grab your order details.</span>
                break;
            case "2":
                message = <span>Your order {order_reference_id} has been canceled.  Your local Party City store can best help answer any questions you may have.  You can find their contact information on your order confirmation email</span>
                break;
            case "3":
                message = <span>Your order {order_reference_id} was delivered.  Please refer to your email for additional information</span>
                break;
        }
        return message;
    }

    const getVerboseStatus = progress => {
        switch (progress.toString()) {
            case "0":
                return "We received your order";
                break;
            case "1":
                return "We've prepared your order";
                break;
            case "2":
                return "Your order is out for delivery";
                break;
            case "3":
                return "Your order is delivered";
                break;
            default:
                return "Please check back later";
                break;
        }
    }

    const getMultiVerboseStatus = progress => {
        switch (progress.toString()) {
            case "0":
                return "We received your order";
                break;
            case "1":
                return "Delivery is almost ready";
                break;
            case "2":
                return "Out for delivery";
                break;
            case "3":
                return "Delivery is completed";
                break;
            default:
                return "Please check back later";
                break;
        }
    }

    const getProgressImgSrc = progress => {
        switch (progress.toString()) {
            case "0":
                return progress_0;
                break;
            case "1":
                return progress_1;
                break;
            case "2":
                return progress_2;
                break;
            case "3":
                return progress_3;
                break;
            default:
                return progress_0;
                break;
        }
    }

    const handleOnIdle = async event => {
        const time = checkOpeningTime();
        if (time == parseInt(process.env.REACT_APP_IDLE_TIMEOUT_INTERVAL_MS) && !earlyCall) {
            if(process.env.REACT_APP_IDLE_TIMEOUT == "true" && firstTimeout) {
                openModalRefresh()
            } else {
                setIdleTime(time);
                setFirstTimeout(true);
                setTimeout(function() {
                    idleTimer.reset();
                }, 2000);
            }
        } else {
            setEarlyCall(true);
            if (time > 0) {
                setIdleTime(3000);
                idleTimer.reset();
            } else {
                if(process.env.REACT_APP_IDLE_TIMEOUT == "true") {
                    openModalRefresh()
                }
            }
        }
    }

    const checkOpeningTime = () => {
        const today = store.expanded_hours[0][moment().format("YYYY-MM-DD")];

        if (today.status == "closed") {
            return parseInt(process.env.REACT_APP_IDLE_TIMEOUT_INTERVAL_MS);
        } else {
            return (earlyCall) ?
                getMSOpenDiff() :
                moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + today.open).subtract(0, "minutes")) ?
                    parseInt(process.env.REACT_APP_IDLE_TIMEOUT_INTERVAL_MS) :
                    moment().isAfter(moment(moment().format("YYYY-MM-DD") + " " + today.open).subtract(30, "minutes")) ?
                        getMSOpenDiff() :
                        parseInt(process.env.REACT_APP_IDLE_TIMEOUT_INTERVAL_MS);
        }
    }

    const getMSOpenDiff = () => {
        const today = store.expanded_hours[0][moment().format("YYYY-MM-DD")];
        const open_dt = moment(moment().format("YYYY-MM-DD") + " " + today.open);
        const now_dt = moment();
        return open_dt.diff(now_dt);
    }

    const applyModalStyleRefresh = () => {
        let top = 0;
        let left = 0;

        /**
         * This is for positioning the arrow for the tooltip to match the info bubble for pickup time disclaimer
         */
        var style = document.createElement('style');
        style.innerHTML = '.ReactModal__Content--after-open {   inset: 0px !important; top: ' + 30+ 'px !important; width: 350px !important; height:170px !important; padding: 10px !important; overflow:hidden !important;  margin-left: auto !important; margin-right: auto !important;}    .ReactModal__Overlay--after-open { background-color: rgba(0, 0, 0, 0.75) !important;  }';
        document.head.appendChild(style);
    }

    const openModalRefresh = () => {
        setIsOpenRefresh(true);
        applyModalStyleRefresh();
    }

    const closeModalReschedule = () => {
        setIsOpenReschedule(false);
    }

    const openModalReschedule = () => {
        setIsOpenReschedule(true);
    }

    const closeModalRefresh = () => {
        setIsOpenRefresh(false);
    }

    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <>

            <LoadingOverlay
                active={loading}
                spinner
                text='Loading your content...'
            >
                <Layout>
                    {(apiError || isExpired) &&
                    <>
                        <ErrorPage isExpired={true} order_id={false} store_name={false} pickedup_time={false} isCancelled={false} isMixedOrder={false} errorMessage={genericError} genericError={genericError} customHeader="Delivery Tracker"/>
                    </>
                    }

                    {(!apiError) &&
                    <>

                        <PageHeader
                            headerText="Delivery Tracker"
                        />
                        <br style={{clear:"both"}}/>
                        <br style={{clear:"both"}}/>
                        <br style={{clear:"both"}}/>
                        <br style={{clear:"both"}}/>

                        <IdleTimer
                            ref={ref => { setIdleTimer(ref) }}
                            element={document}
                            onIdle={handleOnIdle}
                            timeout={idleTime}
                        />

                        {
                            (typeof orders.order_reference_id !== "undefined") &&
                            <>
                                <Row>
                                    <div className="col-9">
                                        {
                                            (!!rescheduleURL) &&
                                            <>
                                                <div style={{textAlign:"left"}}>
                                                    <a href={rescheduleURL} style={{color:"red",fontWeight:"bold",textDecoration:"underline",fontFamily:"futuraptbold", fontSize:"12pt"}}>Reschedule Delivery</a>
                                                </div>
                                            </>
                                        }
                                        {
                                            (!rescheduleURL) &&
                                            <>
                                                <div style={{textAlign:"left"}}>
                                                    <a href="#" onClick={openModalReschedule} style={{color:"red",fontWeight:"bold",textDecoration:"underline",fontFamily:"futuraptbold", fontSize:"12pt"}}>Reschedule Delivery</a>
                                                </div>
                                            </>
                                        }
                                        <h5 style={{fontWeight:"bold"}}><span style={{color:"darkslategrey"}}>Order #:</span> {orders.order_reference_id}</h5>
                                        <h5 style={{fontWeight:"bold"}}><span style={{color:"darkslategrey"}}>Delivery Date:</span><br/>{moment(orders.scheduled_dttm).format("dddd, MMMM D")}</h5>
                                        {
                                            (orders.driver_details.length > 1) &&
                                                <>
                                                    <h5 style={{fontWeight:"bold"}}>*<span style={{color:"red"}}>Multiple Vehicle Delivery</span></h5>
                                                </>
                                        }
                                        {
                                            (pastWindow && !allDelivered && orders.driver_details.length === 1) &&
                                            <>
                                                <h5 style={{fontWeight:"bold"}}>*<span style={{color:"red"}}>Delivery Delayed</span></h5>
                                                {
                                                    orders.driver_details.length === 1 &&
                                                        <>
                                                            <h6 style={{fontWeight:"bold"}}>Don't worry we are on it!</h6>
                                                        </>
                                                }
                                            </>
                                        }
                                    </div>
                                    <div className="col-3" style={{paddingLeft:"0px", paddingRight:"0px"}}>
                                        <img src={header_icon} style={{marginTop:"-20px"}} />
                                    </div>
                                </Row>
                            </>
                        }


                        {
                            (typeof orders.driver_details !== "undefined") &&
                                <>
                                    {
                                        (orders.driver_details.length === 1) &&
                                        <>
                                            <div style={{textAlign:"center"}}>
                                                <h6 style={{fontWeight:"bold"}}>Arrival {orders.driver_details[0].arrive_start}-{orders.driver_details[0].arrive_end}</h6>
                                                <h5 style={{fontWeight:"bold"}}>{(orders.driver_details[0].driver_msg_override !== "") ? orders.driver_details[0].driver_msg_override : getVerboseStatus(orders.driver_details[0].progress)}</h5>
                                                <img src={getProgressImgSrc(orders.driver_details[0].progress)} />
                                            </div>

                                        </>
                                    }
                                    {
                                        (orders.driver_details.length > 1) &&
                                        <>
                                            {
                                                orders.driver_details.map((split, index) => {
                                                   return  <div style={{textAlign:"center"}}>
                                                       <div>
                                                           <div style={{width:"100%",height:"4px", backgroundColor:"grey", marginTop:"15px",position:"relative"}}></div>
                                                           <div className="vehicle-number">
                                                               Vehicle {(index + 1)} of {orders.driver_details.length}
                                                           </div>
                                                       </div>
                                                               <h6 style={{fontWeight:"bold"}}>Arrival {split.arrive_start}-{split.arrive_end}</h6>
                                                               <h5 style={{fontWeight:"bold"}}>{(split.driver_msg_override !== "") ? split.driver_msg_override : getMultiVerboseStatus(split.progress)}</h5>
                                                               <img src={getProgressImgSrc(split.progress)} />
                                                           </div>
                                                })
                                            }
                                        </>
                                    }
                                </>
                        }

                        <br/>

                        {
                            (!!cancelURL) &&
                            <>
                                <div style={{textAlign:"left"}}>
                                    <a href={cancelURL} style={{color:"red",fontWeight:"bold",textDecoration:"underline",fontFamily:"futuraptbold", fontSize:"12pt"}}>Cancel Order</a>
                                </div>
                            </>
                        }

                        {
                            (typeof store.expanded_hours !== "undefined") &&
                            <>
                                <h5 style={{fontWeight:"bold"}}>Order prepared by:</h5>
                                <StoreInformation
                                    store={store}
                                />
                            </>
                        }
                        <br/>

                        {
                            (typeof orders.primary_delivery_vendor !== "undefined") &&
                                <>
                                    {
                                        (orders.primary_delivery_vendor !== "") &&
                                            <>
                                                <h5 style={{fontWeight:"bold",width:"100%",wordWrap:"break-word"}}>Delivery Carrier: {orders.primary_delivery_vendor}</h5>
                                            </>
                                    }
                                </>
                        }

                        <Modal
                            isOpen={modalIsOpenReschedule}
                            onRequestClose={closeModalReschedule}
                        >
                            <p style={{textAlign:"right"}} className="modal-close" onClick={closeModalReschedule}>X</p>
                            <p style={{textAlign:"center",fontSize:"16px", padding:"10px"}}>
                                <b>Oops, Your order cannot be rescheduled at this time. Your local Party City store can best help you answer any questions you may have.</b>
                                <br/>
                                <br/>
                                <button className="closeOMWModal" onClick={closeModalReschedule}>Close</button>
                            </p>
                        </Modal>
                        <Modal
                            isOpen={modalIsOpenRefresh}
                            onRequestClose={closeModalRefresh}
                        >
                            <p style={{textAlign:"center",fontSize:"16px", padding:"10px"}}>
                                <b>That page has expired please click refresh to see current order status</b>
                                <br/>
                                <br/>
                                <button className="closeOMWModal" onClick={refreshPage}>Refresh</button>
                            </p>
                        </Modal>

                    </>
                    }

                </Layout>
            </LoadingOverlay>
        </>
    );
};

export default DeliveryTracker;