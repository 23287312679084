import React from 'react';
import {Row, Col, Modal, Alert, Card, Image} from 'react-bootstrap'
import LoadingOverlay from 'react-loading-overlay-ts';
import PageHeader from "./PageHeader";
import Layout from './Layout';


const IamHere = () => {
   
    
    return (
        <LoadingOverlay
            active={false}
            spinner
            text='Loading your content...'
        >
            <Layout align="center">

               
                    <PageHeader/>
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>
                    <br style={{clear:"both"}}/>

                    <Card>
                        <Row>
                            <Col id="errorMessage">
                                Our site is currently undergoing maintenance, please try again in a few minutes.
                            </Col>
                        </Row>
                        
                            
                    </Card>
                
            </Layout>
        </LoadingOverlay>

    );
};

export default IamHere;