import React, { useState, useEffect } from 'react';
import {Row, Col, Alert,Card} from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import ls from 'localstorage-slim';
import Layout from './Layout';
import './../microsite.css'
import {
    Link, useLocation, useNavigate
} from "react-router-dom";
import {getResponseFromCache, setLocalStorage} from './../util/util.js'
import LoadingOverlay from 'react-loading-overlay-ts';
import VehicleFlowFooter from "./VehicleFlowFooter";
import PageHeader from "./PageHeader";
import ErrorPage from "./ErrorPage";
import UTag from "./UTag";
const ParkingInfo = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const orderParam = new URLSearchParams(search).get('o')
    const order_id = orderParam.substring(2,orderParam.length);
    const initials = orderParam.substring(0,2);
    const shipment_id = new URLSearchParams(search).get('s');
    const order_type = new URLSearchParams(search).get('t');
    const [orders, setOrders] = useState({})
    const [shipments, setShipments] = useState({})
    const [store, setStore] = useState({})
    const [customer, setCustomer] = useState({})
    const [apiError, setApiError] = useState(false)
    const [satisfied, setSatisfied] = useState(false)
    const [parkingSpaceInfo, setParkingSpaceInfo] = useState('')
    const [loading, setLoading] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const [isExpired, setIsExpired] = useState(false);
    const handleIamHere = async  => {
        let orderApiResponse=ls.get(`orderApiResponse_${shipment_id}`)
        let CurrentorderType = orderApiResponse.data.order.order_type  == 'BOPIS' ||  orderApiResponse.data.order.order_type  == 'BALLOON-PICKUP'? 'IS' : 'CS'
        const body={
            "action":"i_am_here",
            "o":orderParam,
            "s": shipment_id,
            "t": order_type,
            "vehicle_type": ls.get('vehicleType'),
            "vehicle_color": ls.get('vehicleColor'),
            "vehicle_make": ls.get('carMakeModel'),
            "bag_location": ls.get('bagLocation'),
            "parking_location": parkingSpaceInfo,
            "CurrentorderType": CurrentorderType
        }
        fetch(`${process.env.REACT_APP_ORDER_API_URL}/iamhere`, {  // Enter your IP address here

            method: 'POST',
            mode: 'cors',
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header

        })
        navigate(`/iamhere${search}`)
        ls.set(`currentPage_${shipment_id}`,"iamhere")

    }
    const handleBack = () => {
        navigate(`/baglocation${search}`)
        ls.set(`currentPage_${shipment_id}`,"baglocation")
    }

    useEffect(async ()=> {
        let currentPage=ls.get(`currentPage_${shipment_id}`)
        currentPage===null? navigate(`/microsite${search}`): navigate(`/${currentPage}${search}`)
        let orders = ls.get(`orders_${order_id}`)
        let customer = ls.get(`customer_${order_id}`)
        let store = ls.get(`store_${order_id}`)
        let shipments = ls.get(`shipment_${shipment_id}`)

        if(orders === null){
            setLoading(true)
            const res = await setLocalStorage(search, order_id, shipment_id)
            if(res){
                setOrders(res.orders)
                setStore(res.store)
                setCustomer(res.customer)
                setShipments(res.shipment);
                setLoading(false)
            }else{
                setLoading(false)
                setApiError(true)
            }
        }else{
            setLoading(true)
            getResponseFromCache(orders, shipments, customer, order_id, initials, shipment_id) ? setApiError(false) : setApiError(true)
            setOrders(orders)
            setCustomer(customer)
            setStore(store)
            setShipments(shipments);
            setLoading(false)
        }
        isFlowSatisfied();
        ls.flush();
    },[parkingSpaceInfo])

    const parkingSpaceEvent = e => {
        setParkingSpaceInfo(e.target.value);
        isFlowSatisfied();
    }

    const cantFindSpotEvent = e => {
        document.querySelector("#parkingSpace-textbox").value = "";
        setIsClicked(!isClicked)
        setParkingSpaceInfo("");
        isFlowSatisfied();
    }


    const isFlowSatisfied = () => {
        setSatisfied((parkingSpaceInfo !== "") ? true : false);
    }

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout align="center">
                {(apiError || isExpired) &&
                <ErrorPage isExpired={isExpired} order_id={orders.order_reference_id}/>
                }
                <PageHeader
                    headerText={(shipments.order_type == "BALLOON-PICKUP" || shipments.order_type == "BOPIS") ? "In Store Pickup" : "Curbside Pickup"}
                />
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <br style={{clear:"both"}}/>
                <Card className="parking-info-card">
                    <Row>
                        <Col id="vehicle-header"><span style={{color:"#018937"}}>STEP 3: </span> Parking Location</Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col id="parkinglocationSubHedaer">Help us find where you are.</Col>
                    </Row>
                    <br/>

                    <Row>
                        <Col id="parking-info-subHeader">
                            Tell us where you are in the parking lot:
                        </Col>
                    </Row>

                    <Row>
                        <Col >
         
            <textarea  className="parkingInfo-textarea"
                       placeholder="Ex. Close to the front of the store, in the third row next to the return shopping carts."
                       name="parkingSpaceInfo"
                       id="parkingSpaceInfo"
                       data-tid="avy"
                       onChange={parkingSpaceEvent}/>
                        </Col>
                    </Row>
                </Card>

                <UTag type="load"
                      page_name={ls.get(`currentPage_${shipment_id}`)}
                      customer={ls.get(`customer_${order_id}`)}
                      orders={ls.get(`orders_${order_id}`)}
                      shipments={ls.get(`shipment_${shipment_id}`)}
                      store={ls.get(`store_${order_id}`)}
                      customer_id={ls.get(`CustomerKey_${(!ls.get(`customer_${order_id}`) ? "" : ls.get(`customer_${order_id}`).email_address)}`)}
                      vehicle_info={{"vehicle_type": "", "vehicle_color": "", "vehicle_make": "", "bag_location": ""}}
                />

                <VehicleFlowFooter
                    satisfied={satisfied} parkingSpaceInfo={parkingSpaceInfo}
                />
            </Layout>
        </LoadingOverlay>
    );
};

export default ParkingInfo;